import * as CognitoHelpers from 'components/Auth/CognitoHelpers';
import { DropdownButton } from 'cymantic-ui/dist/atomic-components/dropdown';
import { UserMenu } from 'cymantic-ui/dist/molecular-components/user-menu';
import { useAppSelector } from 'redux/hooks';

export const UserDropdown = () => {
	const user = useAppSelector((state) => state.auth.user);

	return (
		<UserMenu userName={user.name} userEmail={user.email}>
			<DropdownButton label="Sign Out" onClick={CognitoHelpers.handleRedirectToSignOut} />
		</UserMenu>
	);
};
