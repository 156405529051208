import { Sidebar } from 'components/Sidebar/Sidebar';
import { FlexBox } from 'cymantic-ui/dist/atomic-components/flex-box';
import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const LeftSidebarPageLayout = () => {
	const navigate = useNavigate();
	const location = useLocation();

	React.useEffect(() => {
		const search = new URLSearchParams(location.search);

		navigate({ search: search.toString() }, { replace: true, state: {} });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FlexBox direction="row" height="100vh" width="100%">
			<Sidebar />
			<FlexBox height="100%" width="100%" overflowX="auto">
				<Outlet />
			</FlexBox>
		</FlexBox>
	);
};
