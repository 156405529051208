import { useSearchLocation } from 'hooks/use-search-location';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClusterQueryInput, MemberDoc } from 'redux/features/cluster/cluster.definitions';
import store from 'redux/store';
import { ClusterAction } from 'routes/SearchPage/SearchPage.definitions';
import { decrypt, encrypt, generatedIV } from 'utilities/encryption';

export type UrlInput = {
	query?: string;
	index?: string;
	lens?: string;
	file?: string;
	selectedMemberDocs?: MemberDoc[];
	action: ClusterAction;
	depth: number;
};

type SearchParamKey = 'search' | 'iv';

export const searchParams: Record<SearchParamKey, string> = {
	search: 'search',
	iv: 'iv'
};

type DecryptedParams = Omit<ClusterQueryInput, 'selectedMemberDocs'>;

export function useUrlSearchPage() {
	const location = useSearchLocation();
	const navigate = useNavigate();

	const urlInput = React.useMemo(() => {
		const search = new URLSearchParams(location.search);
		const { user } = store.getState().auth;
		const encryptedParams = search.get(searchParams.search);
		const decryptedString = decrypt(encryptedParams ?? '', user.urlKey ?? '');

		if (decryptedString === null || decryptedString.length === 0) {
			return {
				query: undefined,
				index: undefined,
				lens: undefined,
				file: undefined,
				selectedMemberDocs: undefined,
				action: 'search' as ClusterAction,
				depth: 1
			};
		}
		const decryptedParams = JSON.parse(decryptedString) as DecryptedParams;

		return {
			query: decryptedParams.query,
			index: decryptedParams.index,
			lens: decryptedParams.lens,
			file: decryptedParams.file,
			selectedMemberDocs: location.state?.selectedMemberDocs ?? undefined,
			action: location.state?.action ?? undefined,
			depth: location.state?.depth ?? 1
		};
	}, [
		location.search,
		location.state?.action,
		location.state?.depth,
		location.state?.selectedMemberDocs
	]);

	const handleClusterSearch = React.useCallback(
		(searchData: ClusterQueryInput & { depth?: number }, action: ClusterAction) => {
			const { user } = store.getState().auth;
			const paramsObject = {
				query: searchData.query,
				index: searchData.index,
				lens: searchData.lens,
				file: searchData.file
			};

			const iv = generatedIV();
			const encryptedParams = encrypt(JSON.stringify(paramsObject), user.urlKey ?? '', iv);

			navigate(
				{
					search: `${searchParams.search}=${encodeURIComponent(encryptedParams)}`
				},
				{
					state: {
						selectedMemberDocs: searchData.selectedMemberDocs,
						action,
						depth: searchData.depth ?? 1
					}
				}
			);
		},
		[navigate]
	);

	return {
		urlInput,

		handleClusterSearch
	};
}
