import {
	Button,
	FlexBox,
	IconButton,
	Modal,
	ModalSection,
	RadioCard,
	Text
} from 'cymantic-ui/dist/atomic-components';
import { useDisclosureControl } from 'cymantic-ui/dist/hooks';
import { useForm } from 'react-hook-form';
import { SummaryType } from 'redux/features/summary';
import { useDocPanel } from 'routes/SearchPage/doc-panel/doc-panel-provider';

type DocSettingsValues = {
	summaryType: SummaryType;
};

export const DocPanelSettingsModal = () => {
	const { summaryType, onSummaryTypeChange } = useDocPanel();

	const { isOpen, handleOpen, handleClose } = useDisclosureControl();
	const summaryTypeFieldId = 'summaryType';

	const { register, handleSubmit } = useForm<DocSettingsValues>({
		defaultValues: {
			summaryType
		}
	});

	const onSubmit = (data: DocSettingsValues) => {
		onSummaryTypeChange(data.summaryType);
		handleClose();
	};

	return (
		<>
			<IconButton
				icon="Settings"
				ariaLabel="Open Settings Dialog"
				variant="grey"
				subVariant="outline"
				onClick={handleOpen}
				size="sm"
			/>
			<Modal
				variant="default"
				isOpen={isOpen}
				title="Results Settings"
				onClose={handleClose}
				size="sm"
			>
				<ModalSection>
					<form onSubmit={handleSubmit(onSubmit)}>
						<FlexBox gap="space32">
							<fieldset aria-label="Summarization Settings">
								<FlexBox gap="space16">
									<legend>
										<FlexBox gap="space4">
											<Text variant="bodyXS" color="grey900" tag="div" isBold>
												Summarization
											</Text>
											<Text variant="bodyXXS" color="grey700" tag="div">
												Select how summary content is generated for search
												results.
											</Text>
										</FlexBox>
									</legend>

									<RadioCard
										label="Use article content only"
										description="Summaries will only use content present in original article."
										{...register(summaryTypeFieldId)}
										value="extractive"
									/>
									<RadioCard
										label="Use generative AI"
										description="Generative AI will provide more readable summaries. However, this may be more likely to display inaccuracies."
										{...register(summaryTypeFieldId)}
										value="abstractive"
									/>
								</FlexBox>
							</fieldset>
							<FlexBox gap="space16" direction="row" justifyContent="space-between">
								<Button
									label="Close"
									variant="grey"
									subVariant="outline"
									onClick={handleClose}
								/>
								<Button label="Update Settings" type="submit" />
							</FlexBox>
						</FlexBox>
					</form>
				</ModalSection>
			</Modal>
		</>
	);
};
