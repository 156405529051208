import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from 'components/CenteredLayout/centered-layout';
import {
	AlertVariant,
	CenterContent,
	Icon,
	IconName,
	Link,
	Text
} from 'cymantic-ui/dist/atomic-components';
import { Color } from 'cymantic-ui/dist/theme';
import * as React from 'react';

type PageErrorProps = {
	alertVariant?: AlertVariant;
	title: string;
	subtitle: string;
	children?: React.ReactNode;
	hasContactUs?: boolean;
};

export const alertVariants: Record<AlertVariant, { iconName: IconName; color: Color }> = {
	info: {
		iconName: 'Info',
		color: 'blue700'
	},
	warning: {
		iconName: 'AlertCircle',
		color: 'yellow700'
	},
	error: {
		iconName: 'AlertTriangle',
		color: 'red700'
	},
	success: {
		iconName: 'Check',
		color: 'green700'
	}
};

/**
 * @param alertVariant (OPTIONAL) The type of alert to display
 * @param title A friendly non-technical title for the error
 * @param subtitle Recommend actions the user can take
 * @param children Actions the user can take (EX: retry, return to home, etc)
 * @param hasContactUs (OPTIONAL) Whether or not to display: "If this issue persists, contact us."
 */
export const PageError = ({
	title,
	subtitle,
	children,
	alertVariant,
	hasContactUs
}: PageErrorProps) => (
	<CenterContent isFullHeight>
		<CenteredLayoutContainer>
			<CenteredLayoutSubContainer>
				{alertVariant && (
					<Icon
						icon={alertVariants[alertVariant].iconName}
						color={alertVariants[alertVariant].color}
						size="space48"
					/>
				)}
				<Text tag="h1" variant="headerH4" textAlign="center">
					{title}
				</Text>
				<Text tag="p" variant="bodySM" color="grey700" textAlign="center">
					{subtitle}
				</Text>
			</CenteredLayoutSubContainer>
			<CenteredLayoutSubContainer>{children}</CenteredLayoutSubContainer>
			{hasContactUs && (
				<CenteredLayoutSubContainer>
					<Text tag="p" variant="bodyXS" color="grey700" textAlign="center">
						If this issue persists,{' '}
						<Link label="contact us" href="mailto:support@cymantix.com" />.
					</Text>
				</CenteredLayoutSubContainer>
			)}
		</CenteredLayoutContainer>
	</CenterContent>
);
