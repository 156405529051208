export type MemberDoc = {
	uuid: string;
	score: number;
};

export type Keywords = string[];

export type Group = {
	keywords: Keywords;
	memberDocs: MemberDoc[];
	summary?: string;
};

export type Groups = {
	[key: number]: Group;
};

export type SimiliarityMatrix = {
	[key: number]: {
		[key: number]: number;
	};
};

export type ClusterResponse = {
	groups: Groups;
	totalDocCount: number;
	similarityMatrix: SimiliarityMatrix;
	docIds: ScoredDoc[] | null;
};

export type ClusterDOM = {
	groups: Groups;
	similarityMatrix: SimiliarityMatrix;
	totalDocCount: number;
	code: number;
	userMessage?: string;
	docIds: ScoredDoc[] | null;
};

/**
 * PLEASE NOTE:
 * For document_dom currently documents are either:
 * News, Nixon Tapes, Transcripts, PubMed, or Movies.
 *
 * How we're storing documents in state probably needs some revision.
 * For now:
 * - There's a set of base fields that are shared amonst multiple categories
 * - There are separate types per document category - for these I've set the keys to all optional since they all are eventually merged into one Document type.
 */
type PubMedSourceField = {
	type?: string;
	medium?: string;
	title_abbrev?: string;
	volume?: string;
	issue?: string;
	pagination?: string;
	pub_date?: {
		year: string;
		month: string;
	};
	doi?: string;
	country?: string;
};

type PubMedDocumentFields = {
	pubmed_type?: string;
	authors?: string[];
	abstract?: string;
	affiliations?: string[];
};

type NewsDocumentFields = {
	author?: string;
	description?: string;
	content?: string;
};

type NixonDocumentFields = {
	audio_url_web?: string;
	conversation?: string;
	document_url_web?: string;
	entity_density?: number;
	gpe_entities?: string;
	organization_entities?: string | null;
	people_entities?: string | null;
	slice?: number;
	tape_name?: string;
	transcript_formatted?: string;
};

type MovieDocumentFields = {
	directors?: string[];
	casts?: string[];
	genres?: string[];
	plot?: string;
	release_year?: string;
	origin?: string;
};

type TranscriptDocumentFields = {
	speaker?: string;
	text?: string;
};

type BaseDocumentFields = {
	uuid: string;
	title: string;
	url?: string;
	source?: string | PubMedSourceField;
	date?: string;
};

export type AllDocumentFields = BaseDocumentFields &
	NixonDocumentFields &
	MovieDocumentFields &
	PubMedDocumentFields &
	NewsDocumentFields &
	TranscriptDocumentFields;

export type Document = {
	fields: BaseDocumentFields &
		NixonDocumentFields &
		MovieDocumentFields &
		PubMedDocumentFields &
		NewsDocumentFields &
		TranscriptDocumentFields;
};

export type ClusterDataItem = {
	colorValue: number;
	key: string;
	keywords: string[];
	score: number;
	sizeValue: number;
	summary: string;
	zScore: number;
	memberDocs: MemberDoc[];
};

export type ClusterState = {
	basket: number[];
	data: ClusterDataItem[];
	cachedVis: {
		[key: number]: MemberDoc[];
	};
	selectedMemberDocs: MemberDoc[];
	totalDocCount?: number;
	results?: ClusterDOM;
};

export type ClusterQueryInput = {
	query?: string;
	index?: string;
	lens?: string;
	file?: string;
	selectedMemberDocs?: MemberDoc[];
};

export type ClusterStateUpdate = ClusterDOM & { query: string };

enum DateDisplayType {
	DATE = 'date',
	DATETIME = 'date-time',
	TIMEDATE = 'time-date',
	YEAR = 'year',
	RELATIVE = 'relative'
}

export type DateObjectModel = {
	datetime: number;
	displayType: DateDisplayType;
};

export enum ContentLink {
	PDF = 'pdf',
	TRANSCRIPT = 'transcript',
	WEB = 'web',
	TEXT = 'text'
}

type MainTitleModel = {
	text: string;
	contentLink?: ContentLink;
	externalLink?: string;
};

type SubTitleModel = {
	text: string;
	key?: string;
	externalLink?: string;
};

type SummaryMethodsModel = {
	abstractive: string;
	extractive: string;
};

export type SortOptions = {
	default: string;
	options: string[];
};

type Preview = {
	index: string;
};

export type DocumentModel = {
	uuid: string;
	sortFields: { [key: string]: unknown };
	mainTitle: MainTitleModel;
	date?: DateObjectModel;
	subTitle?: SubTitleModel;
	secondSubTitle?: SubTitleModel;
	preview?: Preview;
};

export type DocsResponse = {
	docList: DocumentModel[];
	summaryMethods: SummaryMethodsModel;
	sortOptions: SortOptions;
};

type ScoredDoc = {
	uuid: string;
	score: number;
};

export type DocsRequest = {
	docIds: string[] | ScoredDoc[];
	index: string;
	lens: string;
};

export type DocPreviewRequest = {
	index: string;
	uuid: string;
};

export type DocPreviewResponse = {
	uuid: string;
	index: string;
	title: string;
	url: string;
};
