import { SkeletonAreaLoader } from 'cymantic-ui/dist/atomic-components/skeleton-loader';
import { docList } from 'routes/SearchPage/doc-panel/doc-panel.styles';

export const DocListSkeleton = () => (
	<div className={docList.skeleton}>
		<SkeletonAreaLoader width="100%" height="7rem" />
		<SkeletonAreaLoader width="100%" height="7rem" />
		<SkeletonAreaLoader width="100%" height="7rem" />
		<SkeletonAreaLoader width="100%" height="7rem" />
		<SkeletonAreaLoader width="100%" height="7rem" />
		<SkeletonAreaLoader width="100%" height="7rem" />
	</div>
);
