import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { getFeatureFlags } from 'utilities/get-feature-flags';

type ProtectedProps = {
	children: React.ReactNode;
};

const Protected = ({ children }: ProtectedProps) => {
	const isSignedIn = useAppSelector((state) => state.auth.isSignedIn);
	const { isAuthEnabled } = getFeatureFlags();

	const isAuthed = isSignedIn || !isAuthEnabled;

	return (
		<>
			{isAuthed && children}
			{!isAuthed && <Navigate to="/" replace />}
		</>
	);
};

export default Protected;
