import { DashboardPageCard } from 'components/DashboardPage/DashboardPageCard';
import { Button, InlineAlert, Input } from 'cymantic-ui/dist/atomic-components';
import { FieldValues, useForm } from 'react-hook-form';
import { joinMeetingCard } from 'routes/DashboardPage/JoinMeetingCard/JoinStartMeetingCard.styles';
import { getRoomLink } from 'utilities/get-meeting-link';

export const JoinMeetingCard = () => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const onSubmit = (data: FieldValues) => {
		const fullPath = getRoomLink(data.roomID);
		window.location.assign(fullPath);
	};

	return (
		<DashboardPageCard title="Join a meeting">
			<form
				aria-label="Join a meeting"
				autoComplete="off"
				className={joinMeetingCard.form}
				onSubmit={handleSubmit(onSubmit)}
			>
				<div>
					<Input
						{...register('roomID', { required: 'Please enter a room ID.' })}
						label="Enter Meeting ID"
						hideLabel
						isInvalid={!!errors.roomID?.type}
						placeholder="Enter Meeting ID"
					/>
				</div>
				<Button label="Join" variant="grey" subVariant="outline" />
			</form>
			{errors.roomID?.message && (
				<InlineAlert variant="error" size="xxs">
					{errors.roomID.message as string}
				</InlineAlert>
			)}
		</DashboardPageCard>
	);
};
