import 'css/index.css';

import App from 'App';
import { ToastNotifications } from 'components/toast-notifications';
import { ThemeInitializer } from 'cymantic-ui/dist/theme';
import { discoverTheme } from 'cymantic-ui/dist/themes';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'redux/store';

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(
	<Provider store={store}>
		<ThemeInitializer theme={discoverTheme}>
			<ToastNotifications />
			<App />
		</ThemeInitializer>
	</Provider>
);
