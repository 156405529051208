import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const sidebar = {
	root: css`
		align-items: center;
		background-color: ${theme.color.white};
		border-right: 2px solid ${theme.color.grey200};
		display: flex;
		flex-direction: column;
		height: 100vh;
		justify-content: space-between;
		padding: ${theme.space.space20} ${theme.space.space8};

		/* IMPORTANT: Position relative here is what properly positions the sidebar menu */
		position: relative;

		/* TODO: move this to ui repo */
		z-index: 99;
	`,
	top: css`
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space12};

		> li:first-child {
			padding-bottom: ${theme.space.space24};
		}
	`,
	links: css`
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space8};
	`,
	userDropdown: css`
		align-items: center;
		display: flex;
		height: 100%;
	`
};
