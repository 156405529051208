import { sidebarLink } from 'components/Sidebar/SidebarItems/SidebarItems.styles';
import { Icon, IconName } from 'cymantic-ui/dist/atomic-components/icon';
import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

type SidebarLinkProps = {
	icon: IconName;
	ariaLabel: string;
	to: NavLinkProps['to'];
};

export const SidebarLink = React.forwardRef<HTMLAnchorElement, SidebarLinkProps>(
	({ icon, ariaLabel, to }, ref) => {
		if (ariaLabel.length === 0) {
			throw new Error('ariaLabel is required, do not enter an empty string');
		}

		return (
			<NavLink
				ref={ref}
				to={to}
				aria-label={ariaLabel}
				className={({ isActive }) => (isActive ? sidebarLink.active : sidebarLink.root)}
			>
				<Icon icon={icon} />
			</NavLink>
		);
	}
);

type SidebarButtonProps = {
	isActive: boolean;
	icon: IconName;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const SidebarButton = React.forwardRef<HTMLButtonElement, SidebarButtonProps>(
	({ icon, isActive, ...buttonProps }, ref) => {
		return (
			<button
				ref={ref}
				className={isActive ? sidebarLink.active : sidebarLink.root}
				{...buttonProps}
			>
				<Icon icon={icon} />
			</button>
		);
	}
);
