import { checkIsUnauthorized, Unauthorized } from 'components/Auth/Unauthorized';
import {
	Button,
	InlineAlert,
	Input,
	ScreenReaderOnly,
	Select
} from 'cymantic-ui/dist/atomic-components';
import { useUrlSearchPage } from 'hooks/use-url-search-page';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ClusterQueryInput } from 'redux/features/cluster';
import {
	setActiveDatabase,
	useGetDatabasesQuery,
	useLazyGetDatabaseLensesQuery
} from 'redux/features/search';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { searchForm, searchSection } from 'routes/SearchPage/SearchForm/SearchForm.styles';
import { isFile, parseFileId } from 'routes/SearchPage/SearchForm/SearchFormHelpers';

const isEqual = (prevInput?: ClusterQueryInput, newInput?: ClusterQueryInput) => {
	if (!prevInput || !newInput) {
		return false;
	}

	return (
		prevInput.query === newInput.query &&
		prevInput.index === newInput.index &&
		prevInput.lens === newInput.lens &&
		prevInput.file === newInput.file
	);
};

type SearchFormProps = {
	hideFormErrors?: boolean;
};

type SearchFormValues = {
	query: string;
	database: string;
	lens: string;
};

const SearchForm = ({ hideFormErrors }: SearchFormProps) => {
	const dispatch = useAppDispatch();
	const previousSearch = useAppSelector((state) => state.search.clusterQuery);
	const user = useAppSelector((state) => state.auth.user);
	const { urlInput, handleClusterSearch } = useUrlSearchPage();

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors }
	} = useForm<SearchFormValues>({
		defaultValues: {
			query: '',
			database: '',
			lens: ''
		}
	});

	/**
	 * Databases
	 */
	const databaseValue = watch('database');
	const {
		data: databasesData,
		isLoading,
		isSuccess,
		error: databasesError
	} = useGetDatabasesQuery();

	/**
	 *  Lenses
	 */
	const [getDatabaseLenses, { data: lensData, error: lensError }] =
		useLazyGetDatabaseLensesQuery();

	React.useEffect(() => {
		if (databaseValue) {
			getDatabaseLenses(databaseValue).then((res) => {
				if (!urlInput.lens) {
					setValue('lens', res?.data?.[databaseValue ?? '']?.default || '');
				}
			});
		}

		dispatch(setActiveDatabase(databaseValue));
	}, [databaseValue, dispatch, getDatabaseLenses, setValue, urlInput.lens]);

	/**
	 * Search/Cluster Query
	 */
	const onSubmit: SubmitHandler<SearchFormValues> = (data) => {
		const searchInfo: ClusterQueryInput = {
			query: data.query,
			index: data.database,
			lens: data.lens,
			file: undefined,
			selectedMemberDocs: undefined
		};

		if (isFile(data.database)) {
			// TODO: this is gross, we need to find another way to distinguish shannon user filesData.
			searchInfo.file = parseFileId(data.database);
			searchInfo.index = 'shannon';
		}

		if (urlInput.depth > 1 || !isEqual(previousSearch, searchInfo)) {
			handleClusterSearch(searchInfo, 'search');
		}
	};

	/**
	 * Render
	 */
	const databaseOptions = React.useMemo(() => {
		const defaultDatabaseOption = { label: 'Select a category', disabled: true, value: '' };
		return databasesData
			? [
					defaultDatabaseOption,
					...Object.entries(databasesData).map(([database, databaseLabel]) => ({
						value: database,
						label: databaseLabel
					}))
			  ]
			: [defaultDatabaseOption];
	}, [databasesData]);

	const hasDatabaseValue = databaseValue || urlInput.index;
	const hasLensValue = lensData && lensData[databaseValue || urlInput.index || ''];

	const lensOptions = React.useMemo(() => {
		return hasDatabaseValue && hasLensValue
			? [
					{ label: 'Select a lens', disabled: true, value: '' },
					...Object.entries(lensData[databaseValue || urlInput.index || ''].lenses).map(
						([value, label]) => ({
							value,
							label
						})
					)
			  ]
			: [];
	}, [databaseValue, hasDatabaseValue, hasLensValue, lensData, urlInput.index]);

	const hasErrors = Object.keys(errors).length > 0;
	const errorMessage = `${
		errors.query?.message || errors.database?.message || errors.lens?.message || ''
	}`;

	// keep hook form state in sync with external search info
	React.useEffect(() => {
		setValue('query', urlInput.query ?? '');
		setValue('database', urlInput.index ?? '');

		if (urlInput.file) {
			const database = databaseOptions.find((d) => d.value.includes(urlInput.file ?? ''));
			setValue('database', database?.value ?? '');
		}

		if (urlInput.lens) {
			setValue('lens', urlInput.lens);
		}
	}, [
		setValue,
		isLoading,
		isSuccess,
		urlInput.index,
		urlInput.file,
		urlInput.lens,
		urlInput.query,
		databaseOptions,
		user.urlKey
	]);

	if (checkIsUnauthorized(databasesError) || checkIsUnauthorized(lensError)) {
		return <Unauthorized />;
	}

	return (
		<section className={searchSection}>
			<ScreenReaderOnly>
				<h2>Search</h2>
				<div>
					All fields are required. Enter a search term, select a category, and select a
					lens.
				</div>
			</ScreenReaderOnly>

			<form
				className={searchForm}
				aria-label="Search"
				aria-describedby={hasErrors ? '#searchErrors' : undefined}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Input
					{...register('query')}
					iconLeft="Search"
					hideLabel
					label="Enter a search term"
					placeholder="Search for anything"
					type="text"
					variant="sm"
				/>

				<Select
					{...register('database', {
						required: 'Please select a category',
						onChange: () =>
							setValue('lens', lensData?.[databaseValue ?? '']?.default ?? '')
					})}
					isInvalid={!!errors.database?.type}
					hideLabel
					label="Select a category"
					options={databaseOptions}
					variant="sm"
				/>

				{hasDatabaseValue && hasLensValue && (
					<Select
						{...register('lens', { required: 'Please select a lens' })}
						isInvalid={!!errors.lens?.type}
						hideLabel
						label="Select a lens"
						options={lensOptions}
						variant="sm"
					/>
				)}

				<Button label="Search" variant="primary" size="sm" />
			</form>

			{hideFormErrors ? (
				<ScreenReaderOnly>
					{hasErrors && (
						<InlineAlert variant="error" size="xs">
							<span>{errorMessage}</span>
						</InlineAlert>
					)}
				</ScreenReaderOnly>
			) : (
				<div
					style={{ visibility: hasErrors ? 'visible' : 'hidden' }}
					aria-hidden={hasErrors || undefined}
				>
					<InlineAlert variant="error" size="xs">
						{errorMessage}
					</InlineAlert>
				</div>
			)}
		</section>
	);
};

export default SearchForm;
