import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from 'components/CenteredLayout/centered-layout';
import { FolderLink } from 'components/FolderLink/FolderLink';
import { PageSection } from 'components/PageSection/PageSection';
import { TableNoData, TableSkeleton } from 'components/Table/helpers';
import { CenterContent, Link, SkeletonAreaLoader, Text } from 'cymantic-ui/dist/atomic-components';
import { Folder } from 'redux/features/library';
import { LibraryFolderRow, LibraryLayoutContent } from 'routes/Library/LibraryLayout';
import { LibraryTable } from 'routes/Library/LibraryTable/LibraryTable';

const meetLink = process.env.REACT_APP_MEET_URL;

export const LibraryPageEmpty = () => (
	<CenterContent isFullHeight>
		<CenteredLayoutContainer>
			<CenteredLayoutSubContainer>
				<Text tag="h1" variant="headerH4" textAlign="center">
					You don’t have any Shannon meeting transcripts yet.
				</Text>
				<Text tag="p" variant="bodySM" color="grey700" textAlign="center">
					When meeting transcripts are saved they will appear here.
				</Text>
			</CenteredLayoutSubContainer>
			<CenteredLayoutSubContainer>
				<Link
					href={meetLink ?? ''}
					isBlank
					iconRight="ExternalLink"
					label="Learn more about Shannon meetings"
				/>
			</CenteredLayoutSubContainer>
		</CenteredLayoutContainer>
	</CenterContent>
);

export const LibraryPageSkeleton = () => (
	<LibraryLayoutContent>
		<PageSection title=" " isLoading>
			<LibraryFolderRow>
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
			</LibraryFolderRow>
		</PageSection>
		<PageSection title=" " description=" " isLoading>
			<TableSkeleton />
		</PageSection>
	</LibraryLayoutContent>
);

type LibraryPageContentProps = {
	data: Folder;
};

export const LibraryPageContent = ({ data }: LibraryPageContentProps) => {
	const hasSubFolders = !!data && data.items.length > 0;
	const hasFiles = !!data && data?.file_count >= 1;

	return (
		<LibraryLayoutContent>
			{hasSubFolders && (
				<PageSection title="Folders">
					<LibraryFolderRow>
						{data.items.map((subfolder) => {
							return (
								<FolderLink
									key={subfolder.id}
									label={subfolder.name}
									to={`/library/folder/${subfolder.id}`}
								/>
							);
						})}
					</LibraryFolderRow>
				</PageSection>
			)}
			<PageSection title="All Files" description="Recent files from all folders">
				{hasFiles ? (
					<LibraryTable folderId="" fileCount={data.file_count} />
				) : (
					<TableNoData message="This folder is currently empty." />
				)}
			</PageSection>
		</LibraryLayoutContent>
	);
};
