import LibraryTree from 'components/LibraryTree/LibraryTree';
import { sidebar } from 'components/Sidebar/Sidebar.styles';
import { SidebarLink } from 'components/Sidebar/SidebarItems/SidebarItems';
import {
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuList
} from 'components/Sidebar/SidebarMenu/SidebarMenu';
import { UserDropdown } from 'components/UserDropdown/UserDropdown';
import { Logo } from 'cymantic-ui/dist/atomic-components/logo';
import { Tooltip, TooltipContent } from 'cymantic-ui/dist/atomic-components/tooltip';
import { Link } from 'react-router-dom';
import { getFeatureFlags } from 'utilities/get-feature-flags';

// Mega menu inspiration: https://accessible-mega-menu.netlify.app/
export const Sidebar = () => {
	const { isAuthEnabled, isLibraryEnabled, isShannonEnabled } = getFeatureFlags();

	const isDashboardEnabled = isShannonEnabled || isLibraryEnabled;

	return (
		<nav role="navigation" aria-label="Main Menu" className={sidebar.root}>
			<ul role="menubar" className={sidebar.top}>
				<li>
					<Link to="/" aria-label="Home">
						<Logo product="Discover" height="2.5rem" width="2.5rem" />
					</Link>
				</li>
				{isDashboardEnabled && (
					<li>
						<Tooltip size="sm" placement="right">
							<SidebarLink ariaLabel="Dashboard" icon="Grid" to="/dashboard" />
							<TooltipContent>Dashboard</TooltipContent>
						</Tooltip>
					</li>
				)}
				<li>
					<Tooltip size="sm" placement="right">
						<SidebarLink ariaLabel="Search" icon="Search" to="/search" />
						<TooltipContent>Search</TooltipContent>
					</Tooltip>
				</li>

				{isLibraryEnabled && (
					<li>
						<SidebarMenu title="Library">
							<SidebarMenuButton to="/library" icon="BookOpen" isActive={false} />
							<SidebarMenuList>
								<li>
									<LibraryTree />
								</li>
							</SidebarMenuList>
						</SidebarMenu>
					</li>
				)}
			</ul>
			{isAuthEnabled && (
				<div>
					<div className={sidebar.userDropdown}>
						<UserDropdown />
					</div>
				</div>
			)}
		</nav>
	);
};
