import { SidebarButton } from 'components/Sidebar/SidebarItems/SidebarItems';
import {
	animateOutDuration,
	closeDelay
} from 'components/Sidebar/SidebarMenu/SidebarMenu.definitions';
import { sidebarMenu } from 'components/Sidebar/SidebarMenu/SidebarMenu.styles';
import { IconName } from 'cymantic-ui/dist/atomic-components/icon';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type SidebarMenuContextProps = {
	title: string;
	isOpen: boolean;
	setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	id: string;
};

const SidebarMenuContext = React.createContext<SidebarMenuContextProps | null>(null);

type SidebarMenuProps = {
	title: string;
	children: React.ReactNode;
};

/**
 * @TODO implement keyboard accessibility: https://accessible-mega-menu.netlify.app/
 */
export const SidebarMenu = ({ title, children }: SidebarMenuProps) => {
	if (title.length === 0) {
		throw new Error('Title is required, do not enter an empty string');
	}

	const location = useLocation();

	const id = `${title.replace(' ', '')}-menu`;

	const [isOpen, setIsOpen] = React.useState(false);
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);

	React.useEffect(() => {
		setIsOpen(false);
	}, [location]);

	// eslint-disable-next-line consistent-return
	React.useEffect(() => {
		if (isMenuOpen) {
			setIsOpen(true);
		} else {
			const timeout = setTimeout(() => {
				setIsOpen(false);
			}, closeDelay);

			return () => clearTimeout(timeout);
		}
	}, [isMenuOpen]);

	const contextValue = React.useMemo(() => {
		return { title, isOpen, id, setIsMenuOpen };
	}, [title, isOpen, id, setIsMenuOpen]);

	return (
		<SidebarMenuContext.Provider value={contextValue}>{children}</SidebarMenuContext.Provider>
	);
};

type SidebarMenuButtonProps = {
	icon: IconName;
	isActive: boolean;
	to: string;
};

/**
 * Note: I don't like using navigate on click, but need to investigate other ways of using this as both a expand/collapse trigger and a link...
 */
export const SidebarMenuButton = ({ icon, isActive, to }: SidebarMenuButtonProps) => {
	const navigate = useNavigate();

	const context = React.useContext(SidebarMenuContext);

	if (context === null) {
		throw new Error('SidebarMenuButton must be used within a SidebarMenu');
	}

	const { title, isOpen, id, setIsMenuOpen } = context;

	return (
		<SidebarButton
			icon={icon}
			isActive={isActive}
			aria-label={title}
			aria-haspopup="true"
			aria-expanded={isOpen}
			aria-controls={id}
			onClick={() => {
				navigate(to);
			}}
			onMouseEnter={() => setIsMenuOpen(true)}
			onMouseLeave={() => setIsMenuOpen(false)}
		/>
	);
};

type SidebarMenuListProps = {
	children: React.ReactNode;
};

/**
 * SidebarMenuList
 * @note Animation is done without AnimatePresence - for assistive technologies the menu is always rendered in the DOM (with associated aria controls/ids) - or at least this is my reasoning
 */
export const SidebarMenuList = ({ children }: SidebarMenuListProps) => {
	const context = React.useContext(SidebarMenuContext);

	if (context === null) {
		throw new Error('SidebarMenuList must be used within a SidebarMenu');
	}

	const { title, isOpen, id, setIsMenuOpen } = context;

	const [canAnimateOut, setCanAnimateOut] = React.useState(false);
	const [isDisplayed, setIsDisplayed] = React.useState(false);

	// eslint-disable-next-line consistent-return
	React.useEffect(() => {
		if (isOpen) {
			setIsDisplayed(true);

			if (!canAnimateOut) {
				setCanAnimateOut(true);
			}
		}

		if (!isOpen) {
			const timeout = setTimeout(() => {
				setIsDisplayed(false);
			}, animateOutDuration + 1);

			return () => clearTimeout(timeout);
		}
	}, [isOpen, canAnimateOut]);

	const getAnimationClass = (emotionClass: string) => {
		if (isOpen) {
			return `${emotionClass} isOpen`;
		}
		if (!isOpen && canAnimateOut) {
			return `${emotionClass} isClosed`;
		}
		return emotionClass;
	};

	return (
		<div
			id={id}
			style={{
				display: isDisplayed ? 'block' : 'none'
			}}
			className={sidebarMenu.root}
			onMouseEnter={() => setIsMenuOpen(true)}
			onMouseLeave={() => setIsMenuOpen(false)}
		>
			<div className={getAnimationClass(sidebarMenu.animationWidth)}>
				<div className={getAnimationClass(sidebarMenu.animationOpacity)}>
					<Text variant="headerH5" color="grey700">
						{title}
					</Text>

					<ul role="menu" className={sidebarMenu.list}>
						{children}
					</ul>
				</div>
			</div>
		</div>
	);
};
