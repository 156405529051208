import { getDomain } from 'components/Auth/CognitoHelpers';
import { checkIsUnauthorized, Unauthorized } from 'components/Auth/Unauthorized';
import { useUrlSearchPage } from 'hooks/use-url-search-page';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useLazyGetDocPanelQuery } from 'redux/features/cluster';
import { SummaryType } from 'redux/features/summary';
import { useAppSelector } from 'redux/hooks';
import { docsReducer, DocsState, initialDocsState } from 'routes/SearchPage/doc-panel/docs-reducer';

type DocPanelContextProps = {
	data: DocsState;
	isLoadingDocs: boolean;
	summaryType: SummaryType;
	onSort: (sortOption: string) => void;
	onSummaryTypeChange: (summaryType: SummaryType) => void;
};

const DocPanelContext = React.createContext<DocPanelContextProps | null>(null);

export const useDocPanel = () => {
	const context = React.useContext(DocPanelContext);

	if (!context) {
		throw new Error('useDocPanelContext must be used within DocPanelContextProvider');
	}

	return context;
};

type DocPanelProviderProps = {
	children: React.ReactNode;
	isDocPanelOpen: boolean;
};

export const DocPanelProvider = ({ children, isDocPanelOpen }: DocPanelProviderProps) => {
	const [data, dispatch] = React.useReducer(docsReducer, initialDocsState);
	const cluster = useAppSelector((s) => s.cluster);
	const { urlInput } = useUrlSearchPage();

	// Memoize the computation of docIds based on the cluster's state to avoid unnecessary recalculations.
	const docIds = React.useMemo(() => {
		return cluster.results?.docIds || cluster.data.map((item) => item.memberDocs).flat();
	}, [cluster]);

	// Determine if all selected documents are present, impacting whether fetching should be skipped.
	const areAllDocsSelected = docIds.every((doc) => data.docIds?.has(doc.uuid));

	const shouldSkipFetch = areAllDocsSelected || !isDocPanelOpen || docIds.length === 0;

	const [
		getDocs,
		{ data: docsData, isLoading: isDocsLoading, error, isFetching, isSuccess: isDocsSuccess }
	] = useLazyGetDocPanelQuery();

	// Effect hook to trigger document fetching, avoiding unnecessary fetches based on defined conditions.
	React.useEffect(() => {
		if (!shouldSkipFetch) {
			getDocs({
				docIds,
				index: urlInput?.index || '',
				lens: urlInput?.lens || ''
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldSkipFetch]);

	const onSort = React.useCallback(
		(sortOption: string) => {
			dispatch({ type: 'SORT_DOCS', payload: sortOption });
		},
		[dispatch]
	);

	const onSummaryTypeChange = React.useCallback(
		(type: SummaryType) => {
			Cookies.set('summaryType', type, getDomain());
			dispatch({ type: 'SET_SUMMARY_TYPE', payload: type });
		},
		[dispatch]
	);

	// Effect hooks to handle state updates in response to successful data fetching and changes in selected documents.
	React.useEffect(() => {
		if (isDocsSuccess && !isDocsLoading && docsData) {
			dispatch({ type: 'SET_DOCS', payload: docsData });
			dispatch({ type: 'FILTER_DOCS', payload: cluster.selectedMemberDocs });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [docsData, isDocsSuccess, isDocsLoading]);

	React.useEffect(() => {
		dispatch({ type: 'FILTER_DOCS', payload: cluster.selectedMemberDocs });
	}, [cluster.selectedMemberDocs]);

	const contextValue = React.useMemo(
		() => ({
			data,
			isLoadingDocs: isDocsLoading || isFetching,
			summaryType: data.summaryType,
			onSort,
			onSummaryTypeChange
		}),
		[data, isDocsLoading, isFetching, onSort, onSummaryTypeChange]
	);

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	return <DocPanelContext.Provider value={contextValue}>{children}</DocPanelContext.Provider>;
};
