import { TranscriptItem, TranscriptPayload } from 'redux/features/meet';

const createFileContent = (transcriptionArray: TranscriptItem[]) => {
	if (transcriptionArray.length === 0) {
		return '';
	}

	return transcriptionArray.reduce((acc, entry) => {
		if (!entry || typeof entry !== 'object' || !entry.speakerName || !entry.text) {
			// Choosing not to throw error here, if a specific entry is invalid, returns acc.
			// Theoretically this should happen (should be caught in retrieveStoredTranscript)
			return acc;
		}

		const { speakerName, text } = entry;
		return `${acc}${speakerName}: ${text}\n`;
	}, '');
};

// NOTE: This is pulled directly from meet to match. Prevents duplicate files from being added.
export const getFileTitle = (roomId: string) => {
	const d = new Date();
	const month = String(d.getMonth() + 1).padStart(2, '0');
	const day = String(d.getDate()).padStart(2, '0');
	const year = String(d.getFullYear()).padStart(2, '0');
	const filename = `${roomId}_${month}${day}${year}`;

	return filename;
};

export const downloadTranscriptFile = ({
	roomId,
	transcriptArray,
	onError,
	onSuccess
}: {
	roomId: string;
	transcriptArray: TranscriptItem[];
	onError: () => void;
	onSuccess: () => void;
}) => {
	const transcriptToDownload = createFileContent(transcriptArray);
	const filename = `${roomId}_transcript.txt`;
	const blob = new Blob([transcriptToDownload], { type: 'text/plain' });

	if (!filename || !blob) {
		onError();
		return;
	}

	// This will not work on IE
	const downloadLink = document.createElement('a');
	downloadLink.href = URL.createObjectURL(blob);
	downloadLink.download = filename;
	downloadLink.target = '_blank';
	downloadLink.click();
	downloadLink.remove();
	onSuccess();
};

export const formatUploadPayload = ({
	transcriptArray,
	roomId
}: {
	transcriptArray: TranscriptItem[];
	roomId: string;
}) => {
	const timestamp = transcriptArray?.[0].timestamp;
	const date = timestamp ? new Date(timestamp).toISOString() : new Date().toISOString();
	const participants = [...new Set(transcriptArray.map((transcript) => transcript.speakerName))];

	const payload: TranscriptPayload = {
		title: getFileTitle(roomId),
		transcript: transcriptArray || [],
		date,
		participants
	};

	return payload;
};
