import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const dashboardPage = {
	main: css`
		background: ${theme.color.grey50};
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space32};
		min-height: 100%;
		overflow-y: auto;
		padding: ${theme.space.space32};
	`,
	titleContainer: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space16};
		width: 100%;
	`,
	cardContainer: css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: ${theme.space.space16};
		width: 100%;

		> * {
			flex: 1;
			min-width: 20rem;
		}
	`
};
