import { checkIsUnauthorized, Unauthorized } from 'components/Auth/Unauthorized';
import { CenteredLayoutContainer } from 'components/CenteredLayout/centered-layout';
import { OpacityTransition } from 'components/opacity-transition';
import { PageHeader, PageHeaderLeft } from 'components/PageHeader/PageHeader';
import { ShuffleHeader } from 'components/ShuffleHeader/ShuffleHeader';
import {
	CenterContent,
	FlexBox,
	IconButtonWithTooltip,
	RouterLink,
	TabButton,
	TabList,
	TabPanel,
	Tabs
} from 'cymantic-ui/dist/atomic-components';
import { useDisclosureControl } from 'cymantic-ui/dist/hooks';
import { AnimatePresence } from 'framer-motion';
import { useUrlSearchPage } from 'hooks/use-url-search-page';
import * as React from 'react';
import { checkIsErrorCode } from 'redux/error-helpers';
import {
	addNodeToBasket,
	ClusterQueryInput,
	ClusterStateUpdate,
	removeNodeFromBasket,
	setSearchResults,
	setZoomInResults,
	setZoomOutFromBasketResults,
	setZoomOutResults,
	startSearch,
	startZoomIn,
	startZoomOut,
	startZoomOutFromBasket,
	useGetClustersQuery
} from 'redux/features/cluster';
import { setClusterQuery } from 'redux/features/search';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as ClusterStates from 'routes/SearchPage/ClusterView/ClusterStates';
import ClusterView from 'routes/SearchPage/ClusterView/ClusterView';
import { ZoomInButton, ZoomOutButton } from 'routes/SearchPage/controls';
import DocPanel from 'routes/SearchPage/doc-panel/doc-panel';
import { DocPanelProvider } from 'routes/SearchPage/doc-panel/doc-panel-provider';
import { ListItem } from 'routes/SearchPage/list-view/list-item';
import { ListViewLoader } from 'routes/SearchPage/list-view/loader';
import { RelativityPanel } from 'routes/SearchPage/RelativityPanel/RelativityPanel';
import SearchForm from 'routes/SearchPage/SearchForm/SearchForm';
import { ClusterAction } from 'routes/SearchPage/SearchPage.definitions';
import { searchPage } from 'routes/SearchPage/SearchPage.styles';

export const SearchPage = () => {
	const dispatch = useAppDispatch();
	const cluster = useAppSelector((s) => s.cluster);
	const clusterResults = useAppSelector((s) => s.cluster.results);
	const activeDatabase = useAppSelector((s) => s.search.activeDatabase);
	const basket = useAppSelector((s) => s.cluster.basket);

	const { urlInput } = useUrlSearchPage();
	const selectedItems = useAppSelector((s) => s.cluster.basket);

	const [clusterAction, setClusterAction] = React.useState<ClusterAction>();
	const [shouldAutoOpenDocPanel, setShouldAutoOpenDocPanel] = React.useState(true);

	const {
		isOpen: isDocPanelExpanded,
		handleClose: handleCollapse,
		handleOpen: handleExpand,
		handleToggle
	} = useDisclosureControl({
		onClose: () => setShouldAutoOpenDocPanel(false)
	});

	const shouldSkip = !urlInput.index || !urlInput.lens;

	const {
		data: results,
		isSuccess,
		isFetching,
		isLoading,
		isError,
		isUninitialized,
		status,
		error
	} = useGetClustersQuery(
		{
			index: urlInput.index,
			lens: urlInput.lens,
			query:
				urlInput?.selectedMemberDocs && urlInput?.selectedMemberDocs?.length > 0
					? undefined
					: urlInput.query,
			file: urlInput.file,
			selectedMemberDocs: urlInput.selectedMemberDocs
		},

		{
			skip: shouldSkip,
			refetchOnMountOrArgChange: true
		}
	);

	const isShannonFile =
		urlInput?.index === 'shannon' &&
		typeof activeDatabase === 'string' &&
		activeDatabase.includes('file-');

	const linkToShannonFile = isShannonFile
		? `/library/file/transcript/${activeDatabase.replace('file-', '')}`
		: '';

	const hasClusters = cluster.data.length > 0;
	const hasPreviousError = clusterResults?.code === 500;
	const is404 = checkIsErrorCode(404, error);
	const hasError = (isError || hasPreviousError) && !is404;
	const hasSuccess = isSuccess && hasClusters;
	const hasSearchParams = !!urlInput.index && !!urlInput.lens;

	const isClusterView = !isFetching && !isLoading && hasClusters && hasSuccess;
	const isErrorState = !isFetching && !isLoading && hasError && !isUninitialized;
	const isInitialState = !isError && !hasSearchParams;
	const isNoClustersState =
		!isFetching &&
		!isLoading &&
		!hasError &&
		cluster.data.length === 0 &&
		!!clusterResults?.totalDocCount &&
		clusterResults?.totalDocCount > 0;

	const isNoResultsState = !isFetching && !isLoading && is404;

	const notAllSelected = cluster.basket.length !== Object.entries(cluster.data).length;
	const selectedDocCount = cluster.selectedMemberDocs.length;
	const hasClusterVisData = !!cluster.data.length;

	const isZoomInDisabled =
		(isFetching && isLoading) ||
		(selectedDocCount <= 22 && notAllSelected) ||
		!hasClusterVisData;
	const isZoomOutDisabled =
		(isFetching && isLoading) || urlInput.depth <= 1 || !hasClusterVisData;

	const handleClusterQuery = React.useCallback(
		(searchQuery: ClusterQueryInput, action: ClusterAction) => {
			setClusterAction(action);

			if (action === 'search') {
				dispatch(startSearch());
				dispatch(setClusterQuery({ ...searchQuery, query: urlInput.query }));
			}

			if (action === 'zoomIn') {
				dispatch(startZoomIn(urlInput.depth));
			}

			if (action === 'zoomOut') {
				dispatch(startZoomOut());
			}

			if (action === 'zoomOutBasket') {
				dispatch(startZoomOutFromBasket(urlInput.depth));
			}
		},
		[dispatch, urlInput.depth, urlInput.query]
	);

	const getActionType = React.useCallback((action: ClusterAction) => {
		switch (action) {
			case 'search':
				return setSearchResults;
			case 'zoomIn':
				return setZoomInResults;
			case 'zoomOut':
				return setZoomOutResults;
			case 'zoomOutBasket':
				return setZoomOutFromBasketResults;
			default:
				return null;
		}
	}, []);

	React.useEffect(() => {
		if (clusterAction) {
			const actionType = getActionType(clusterAction);
			if (actionType && status === 'fulfilled') {
				const updateObj = {
					...results,
					query: urlInput.query || '',
					code: isSuccess ? 200 : 500,
					error: isError ? error : null
				} as ClusterStateUpdate;

				dispatch(actionType(updateObj));
			}
		}
	}, [
		dispatch,
		isFetching,
		isSuccess,
		isError,
		error,
		getActionType,
		results,
		status,
		urlInput.query,
		clusterAction
	]);

	React.useEffect(() => {
		handleClusterQuery(
			{
				query: urlInput.query,
				index: urlInput.index,
				lens: urlInput.lens,
				file: urlInput.file,
				selectedMemberDocs: urlInput.selectedMemberDocs
			},
			urlInput.action ?? 'search'
		);
	}, [
		handleClusterQuery,
		urlInput.action,
		urlInput.index,
		urlInput.file,
		urlInput.lens,
		urlInput.selectedMemberDocs,
		urlInput.query
	]);

	React.useEffect(() => {
		if (isNoClustersState) {
			handleExpand();
		}
	}, [handleExpand, isNoClustersState]);

	React.useEffect(() => {
		if (isError) {
			handleCollapse();
		}
	}, [handleCollapse, isError]);

	// Auto open doc panel on first cluster click
	// This makes the document panel more discoverable for the user
	React.useEffect(() => {
		const shouldExpand = !isDocPanelExpanded && basket.length === 1 && shouldAutoOpenDocPanel;
		if (shouldExpand) {
			handleExpand();
			setShouldAutoOpenDocPanel(false);
		}

		if (basket.length === 0) {
			setShouldAutoOpenDocPanel(true);
		}
	}, [basket, isDocPanelExpanded, shouldAutoOpenDocPanel, handleExpand]);

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	const handleAddOrRemoveCluster = (id: number) =>
		dispatch(selectedItems.includes(id) ? removeNodeFromBasket(id) : addNodeToBasket(id));

	return (
		<DocPanelProvider isDocPanelOpen={isSuccess && isDocPanelExpanded}>
			<div
				className={searchPage.root}
				style={{
					gridTemplateRows: isInitialState
						? 'auto minmax(auto, 1fr)'
						: 'auto auto minmax(auto, 1fr)'
				}}
			>
				<PageHeader>
					<PageHeaderLeft title="Search" />
					{!isInitialState && <SearchForm hideFormErrors />}
				</PageHeader>

				{isInitialState && (
					<CenterContent isFullHeight>
						<CenteredLayoutContainer>
							<ShuffleHeader />
							<SearchForm />
						</CenteredLayoutContainer>
					</CenterContent>
				)}

				{!isInitialState && (
					<Tabs isPadded={false} isFullHeight>
						<TabList>
							<TabButton label="Cluster View" id="cluster" />
							<TabButton label="List View" id="list" />
						</TabList>
						<FlexBox direction="row">
							<div className={searchPage.contentInner}>
								<TabPanel tabId="cluster">
									<div className={searchPage.tabRoot}>
										<AnimatePresence>
											{isClusterView && (
												<OpacityTransition key="relativity">
													<RelativityPanel />
												</OpacityTransition>
											)}
										</AnimatePresence>

										{(isFetching || isLoading) && <ClusterStates.Loading />}
										{isClusterView && <ClusterView />}
										{isNoClustersState && (
											<ClusterStates.NoClusters>
												{isShannonFile && (
													<RouterLink
														to={linkToShannonFile}
														label="Go to full transcript →"
													/>
												)}
											</ClusterStates.NoClusters>
										)}
										{isNoResultsState && (
											<ClusterStates.NoResults>
												{isShannonFile && (
													<RouterLink
														to={linkToShannonFile}
														label="Go to full transcript →"
													/>
												)}
											</ClusterStates.NoResults>
										)}
										{isErrorState && (
											<ClusterStates.Error
												userMessage={clusterResults?.userMessage}
											/>
										)}
									</div>
								</TabPanel>
								<TabPanel tabId="list">
									<div className={searchPage.tableViewPanel}>
										{(isFetching || isLoading) && <ListViewLoader />}

										{isNoResultsState && (
											<ClusterStates.NoResults>
												{isShannonFile && (
													<RouterLink
														to={linkToShannonFile}
														label="Go to full transcript →"
													/>
												)}
											</ClusterStates.NoResults>
										)}
										{isErrorState && (
											<ClusterStates.Error
												userMessage={clusterResults?.userMessage}
											/>
										)}
										{isNoClustersState && (
											<ClusterStates.NoClusters>
												{isShannonFile && (
													<RouterLink
														to={linkToShannonFile}
														label="Go to full transcript →"
													/>
												)}
											</ClusterStates.NoClusters>
										)}
										{isClusterView && (
											<FlexBox
												width="100%"
												padding="space20"
												overflowX="auto"
											>
												<FlexBox
													tag="ul"
													width="100%"
													gap="space16"
													pb="space20"
												>
													{cluster.data.map((c) => (
														<ListItem
															key={c.key}
															count={c.sizeValue}
															isSelected={selectedItems.includes(
																Number(c.key)
															)}
															relativityScore={c.score}
															title={c.keywords.join(', ')}
															onClick={() =>
																handleAddOrRemoveCluster(
																	Number(c.key)
																)
															}
														/>
													))}
												</FlexBox>
											</FlexBox>
										)}
									</div>
								</TabPanel>
								<AnimatePresence>
									{isClusterView && (
										<OpacityTransition key="bottomControls">
											<div className={searchPage.bottomControls}>
												<IconButtonWithTooltip
													tooltip="Toggle Results Panel"
													icon="List"
													variant="grey"
													subVariant="outline"
													onClick={handleToggle}
													ariaLabel="Toggle Results Panel"
													size="md"
													disabled={!hasSuccess}
												/>
												<ZoomInButton isDisabled={isZoomInDisabled} />
												<ZoomOutButton isDisabled={isZoomOutDisabled} />
											</div>
										</OpacityTransition>
									)}
								</AnimatePresence>
							</div>
							<DocPanel
								isLoading={isFetching && !isLoading}
								isExpanded={isDocPanelExpanded}
								handleClose={handleCollapse}
							/>
						</FlexBox>
					</Tabs>
				)}
			</div>
		</DocPanelProvider>
	);
};
