import { checkIsUnauthorized, Unauthorized } from 'components/Auth/Unauthorized';
import { DashboardPageCard } from 'components/DashboardPage/DashboardPageCard';
import { TableError, TableNoData, TableSkeleton } from 'components/Table/helpers';
import * as React from 'react';
import { useGetFilesQuery } from 'redux/features/library';
import { RecentFilesTable } from 'routes/DashboardPage/RecentFilesCard/RecentFilesTable';
import { PageTransition } from 'routes/Library/LibraryTransition';

export const RecentFilesCard = () => {
	const {
		data: tableData,
		isLoading: isTableLoading,
		isError: isTableError,
		error: tableError
	} = useGetFilesQuery({
		folderId: '',
		page: 1
	});
	const [isTableLoaded, setIsTableLoaded] = React.useState(false);

	const isTableEmpty = !tableData || tableData.length === 0;

	React.useEffect(() => {
		if (!isTableLoading) {
			setIsTableLoaded(true);
			return;
		}

		const timeout = setTimeout(() => setIsTableLoaded(false), 1000);
		// eslint-disable-next-line consistent-return
		return () => clearTimeout(timeout);
	}, [isTableLoading]);

	if (checkIsUnauthorized(tableError)) {
		return <Unauthorized />;
	}

	return (
		<>
			{!isTableLoaded && (
				<PageTransition key="tableLoader">
					<TableSkeleton />
				</PageTransition>
			)}

			{isTableLoaded && isTableError && (
				<PageTransition key="tableError" delay={0.3}>
					<TableError />
				</PageTransition>
			)}

			{isTableLoaded && !isTableError && (
				<PageTransition key="tableFiles" delay={0.3}>
					<DashboardPageCard title="Recent Files">
						{!isTableEmpty ? (
							<RecentFilesTable data={tableData ?? []} />
						) : (
							<TableNoData message="No recent files found." />
						)}
					</DashboardPageCard>
				</PageTransition>
			)}
		</>
	);
};
