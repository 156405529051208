import { ActionCard } from 'components/action-card/action-card';
import { getDomain } from 'components/Auth/CognitoHelpers';
import {
	Button,
	FlexBox,
	LinkButton,
	RouterLink,
	SkeletonAreaLoader
} from 'cymantic-ui/dist/atomic-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useToast } from 'hooks/use-toast';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useLazyGetFilesQuery } from 'redux/features/library';
import {
	useLazyGetTemporaryTranscriptsQuery,
	useLazyUploadTranscriptQuery
} from 'redux/features/meet';
import { toastContents } from 'routes/DashboardPage/meeting-ended-card/meeting-ended-card.definitions';
import { meetingEndedCard } from 'routes/DashboardPage/meeting-ended-card/meeting-ended-card.styles';
import {
	downloadTranscriptFile,
	formatUploadPayload,
	getFileTitle
} from 'routes/DashboardPage/meeting-ended-card/meeting-ended-utils';
import { getRoomLink } from 'utilities/get-meeting-link';

export const MeetingEndedCard = () => {
	const { addToast } = useToast();
	const [getFilesData, { data: filesData }] = useLazyGetFilesQuery();
	const [getTemporaryTranscripts, { data: transcriptArray }] =
		useLazyGetTemporaryTranscriptsQuery();
	const [uploadTranscript] = useLazyUploadTranscriptQuery();
	const [isOpen, setIsOpen] = React.useState(true);

	const roomId = Cookies.get('hasLeftMeeting');
	const wasInMeeting = !!roomId;
	const showCard = wasInMeeting && isOpen;
	const isLoading = !transcriptArray && wasInMeeting;

	// PLEASE NOTE: transcriptTitle formatting must match the transcriptTitle assigned by Meet for this to work (otherwise, the transcript will not be found in the library)
	const transcriptTitle = roomId ? getFileTitle(roomId) : '';
	const transcriptLibraryFile = filesData?.find((f) => f.name === transcriptTitle);
	const hasTranscript = transcriptArray && transcriptArray.length > 0;
	const isUploadedToLibrary = !!transcriptLibraryFile && !!transcriptLibraryFile.id;

	// If roomId is present:
	// - Get recent files list (for checking if transcript is already uploaded)
	// - Get temporary transcripts (for grabbing the transcript data)
	React.useEffect(() => {
		if (roomId) {
			getFilesData({ folderId: '', page: 1 });
			getTemporaryTranscripts(roomId);
		}
	}, [roomId, getFilesData, getTemporaryTranscripts]);

	// Removing the cookie to prevent the card from showing again
	const handleClose = () => {
		setIsOpen(false);
		Cookies.remove('hasLeftMeeting', getDomain());
	};

	const handleReturnToMeeting = React.useCallback(() => {
		window.location.href = getRoomLink(roomId ?? '');
	}, [roomId]);

	const handleDownloadTranscript = React.useCallback(() => {
		if (roomId && transcriptArray) {
			downloadTranscriptFile({
				roomId,
				transcriptArray,
				onError: () => addToast(toastContents.downloadError),
				onSuccess: () => addToast(toastContents.downloadSuccess)
			});
		}
	}, [roomId, transcriptArray, addToast]);

	const handleUploadTranscript = React.useCallback(() => {
		if (roomId && transcriptArray) {
			const payload = formatUploadPayload({ transcriptArray, roomId });

			uploadTranscript(payload)
				.unwrap()
				.then(() => {
					addToast(toastContents.addedToLibrarySuccess);

					// Update recent files table
					getFilesData({ folderId: '', page: 1 });
				})
				.catch(() => {
					addToast(toastContents.addedToLibraryError);
				});
		}
	}, [transcriptArray, roomId, uploadTranscript, addToast, getFilesData]);

	return (
		<AnimatePresence>
			{showCard && (
				<motion.div
					key="meetingEndedCard"
					style={{ display: 'grid' }}
					initial={{ gridTemplateRows: '0fr', opacity: 1 }}
					animate={{ gridTemplateRows: '1fr', opacity: 1 }}
					exit={{ gridTemplateRows: '0fr', opacity: 0 }}
					transition={{ duration: 0.3, delay: 0.3 }}
				>
					<div className={meetingEndedCard.overflowWrapper}>
						<div
							style={{
								opacity: isLoading ? 0 : 1,
								transition: 'opacity 0.5s',
								transitionDelay: '2s'
							}}
						>
							<ActionCard
								icon="LogOut"
								variant="info"
								title="You left the meeting."
								description={
									hasTranscript
										? 'To save your meeting transcript you may download it or add it to your library. If you selected to auto upload your transcript, it will appear in your library.'
										: undefined
								}
								onClose={handleClose}
							>
								<FlexBox
									direction="row"
									alignItems="center"
									justifyContent="space-between"
									width="100%"
									wrap="wrap"
									gap="space16"
								>
									<LinkButton
										iconLeft="ArrowLeft"
										label="Back to Meeting"
										variant="bodyXS"
										onClick={handleReturnToMeeting}
									/>

									{hasTranscript && (
										<FlexBox direction="row" gap="space16" wrap="wrap">
											<Button
												iconLeft="Download"
												label="Download Transcript"
												variant="grey"
												subVariant="outline"
												onClick={handleDownloadTranscript}
											/>

											<div className={meetingEndedCard.mainActionWrapper}>
												<div
													style={{
														visibility: isUploadedToLibrary
															? 'hidden'
															: 'visible'
													}}
												>
													<Button
														iconLeft="Plus"
														label="Add to Library"
														onClick={handleUploadTranscript}
													/>
												</div>
												<AnimatePresence>
													{isUploadedToLibrary && (
														<motion.div
															initial={{ opacity: 0 }}
															animate={{ opacity: 1 }}
															exit={{ opacity: 0 }}
															transition={{ duration: 0.3 }}
															className={
																meetingEndedCard.mainActionLink
															}
														>
															<RouterLink
																variant="bodyXS"
																to={`/library/file/transcript/${transcriptLibraryFile.id}`}
																label="Go to Transcript"
																iconRight="ArrowRight"
															/>
														</motion.div>
													)}
												</AnimatePresence>
											</div>
										</FlexBox>
									)}
								</FlexBox>
							</ActionCard>
						</div>
						<AnimatePresence>
							{isLoading && (
								<motion.div
									className={meetingEndedCard.skeletonWrapper}
									initial={{ opacity: 1 }}
									animate={{ opacity: 0 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5, delay: 2 }}
								>
									<SkeletonAreaLoader height="100%" width="100%" />
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
