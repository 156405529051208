import { PageError } from 'components/PageError/PageError';

export const LibraryPageError = () => (
	<PageError
		alertVariant="error"
		title="An error occurred while processing your request."
		subtitle="Please try again later."
		hasContactUs
	/>
);
