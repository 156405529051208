import { css, keyframes } from '@emotion/css';
import {
	animateInDuration,
	animateOutDuration
} from 'components/Sidebar/SidebarMenu/SidebarMenu.definitions';
import { theme } from 'cymantic-ui/dist/theme';

const fadeIn = keyframes`
	0%, 10% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const fadeOut = keyframes`
	0%, 10% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`;

const increaseWidth = keyframes`
	0%, 10% {
		width: 0rem;
	}
	100% {
		width: 20rem;
	}
`;

const decreaseWidth = keyframes`
	0%, 10% {
		width: 20rem;
	}
	100% {
		width: 0rem;
	}
`;

export const sidebarMenu = {
	root: css`
		height: 100%;
		left: calc(100% + 2px);
		position: absolute;
		top: 0;
	`,
	animationWidth: css`
		background-color: ${theme.color.white};
		box-shadow: 5px 0 6px 0 rgb(0 0 0 / 7.5%);
		height: 100%;
		overflow-x: hidden;
		width: 0;

		&.isOpen {
			animation: ${increaseWidth} ${animateInDuration}ms ease-out forwards;
			width: 20rem;
		}

		&.isClosed {
			animation: ${decreaseWidth} ${animateOutDuration}ms ease-in forwards;
			width: 0;
		}
	`,
	animationOpacity: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space32};
		opacity: 0;
		padding: ${theme.space.space32} ${theme.space.space24};

		&.isOpen {
			animation: ${fadeIn} ${animateInDuration}ms linear forwards;
			opacity: 1;
		}

		&.isClosed {
			animation: ${fadeOut} ${animateOutDuration}ms linear forwards;
			opacity: 0;
		}
	`,
	list: css`
		color: ${theme.color.grey700};
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space16};
	`
};
