import Cookies from 'js-cookie';
import { DocumentModel } from 'redux/features/cluster';
import { SummaryType } from 'redux/features/summary';

export const summaryTypeGuard = (type: string): type is SummaryType => {
	return ['abstractive', 'extractive'].includes(type);
};

const summaryTypeDefault = process.env.REACT_APP_DEFAULT_SUMMARY_METHOD;

export const getSummaryTypeInitialState = () => {
	const summaryTypeCookie = Cookies.get('summaryType');

	const defaultType =
		summaryTypeDefault && summaryTypeGuard(summaryTypeDefault)
			? (summaryTypeDefault as SummaryType)
			: 'extractive';

	const summaryTypeInitialState =
		summaryTypeCookie && summaryTypeGuard(summaryTypeCookie)
			? (summaryTypeCookie as SummaryType)
			: defaultType;

	return summaryTypeInitialState;
};

export const sortDocuments = (docs: DocumentModel[], sortOptions: string) => {
	return [...docs]
		.sort((a, b) => {
			const aField = a.sortFields[sortOptions] as number;
			const bField = b.sortFields[sortOptions] as number;

			if (aField && bField) {
				// eslint-disable-next-line no-nested-ternary
				return aField > bField ? 1 : aField < bField ? -1 : 0;
			}
			return 0;
		})
		.reverse();
};
