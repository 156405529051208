import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const joinMeetingCard = {
	form: css`
		display: flex;
		flex-wrap: wrap;
		gap: ${theme.space.space16};

		> *:first-child {
			flex: 4;
			min-width: 18rem;
		}

		> *:last-child {
			flex: 1;
		}
	`,
	wrapper: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space8};
	`
};
