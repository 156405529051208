import * as d3 from 'd3';
import * as settings from './ClusterView.definitions';

/**
 * getMinMaxArrValues
 * @param arr: Array of numbers
 * @returns: { minSize, maxSize }
 */
export function getMinMaxArrValues(arr) {
	const minSize = Math.min.apply(null, arr);
	const maxSize = Math.max.apply(null, arr);

	return { minSize, maxSize };
}

/**
 * createD3BubbleScaler
 * @param {*} minSize: smallest cluster size
 * @param {*} maxSize: largest cluster size
 * @returns: { getBubbleScale } function that is used for formatting data to set up the bubble chart root, determines how bubbles are scaled
 * @note .scaleLinear also looks good
 */
export function createD3BubbleScaler(minSize, maxSize) {
	const getBubbleScale = d3
		.scaleLog()
		.domain([minSize, maxSize])
		.range([settings.minimumBubbleSize, settings.maximumBubbleSize]);

	return { getBubbleScale };
}

/**
 * resetClusterView: Removes SVG, tooltip & resets data
 * @param {*} svgRef
 */
export function resetClusterView(svgRef) {
	d3.select(svgRef.current).selectAll('svg').remove();
	d3.select(`${settings.containerClass} .tooltip`).remove();
}
