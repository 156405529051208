import { container, subContainer } from 'components/CenteredLayout/centered-layout.styles';
import * as React from 'react';

type CenteredLayoutContainerProps = {
	children?: React.ReactNode;
};

export const CenteredLayoutContainer = ({ children }: CenteredLayoutContainerProps) => (
	<div className={container}>{children}</div>
);

export const CenteredLayoutSubContainer = ({ children }: CenteredLayoutContainerProps) => (
	<div className={subContainer}>{children}</div>
);
