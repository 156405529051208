import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Folder, LibraryState } from 'redux/features/library/library.definitions';
import { libraryQueries } from 'redux/features/library/library-queries';

const initialState: LibraryState = {
	folders: []
};

export const librarySlice = createSlice({
	name: 'library',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		/**
		 * This action occurs automatically on the getLibrary api call succeeding
		 */
		builder.addMatcher(
			libraryQueries.endpoints.getFolders.matchFulfilled,
			(state, action: PayloadAction<Folder[]>) => {
				return {
					...state,
					folders: action.payload
				};
			}
		);
	}
});

export const libraryReducer = librarySlice.reducer;
