import { PageError } from 'components/PageError/PageError';
import { CenterContent } from 'cymantic-ui/dist/atomic-components/center-content';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import * as React from 'react';
import { BeatLoader } from 'react-spinners';
import { clusterView } from 'routes/SearchPage/ClusterView/ClusterView.styles';
import formatContactUs from 'utilities/format-contact-us';

export const Loading = () => (
	<div className={clusterView.loaderOverlay}>
		<CenterContent>
			<div className={clusterView.loaderContainer}>
				<BeatLoader size={15} color="#3E5CE0" loading />
			</div>
		</CenterContent>
	</div>
);

export const NoClusters = ({ children }: { children?: React.ReactNode }) => (
	<CenterContent>
		<Text variant="bodySM" fontStyle="italic" color="grey600">
			No clusters to show. {children}
		</Text>
	</CenterContent>
);

export const NoResults = ({ children }: { children?: React.ReactNode }) => (
	<CenterContent>
		<Text variant="bodySM" fontStyle="italic" color="grey600">
			No results found. {children}
		</Text>
	</CenterContent>
);

export const Error = ({ userMessage }: { userMessage?: string }) =>
	userMessage ? (
		<PageError
			alertVariant="error"
			title={`${formatContactUs(userMessage)}`}
			subtitle="Please try again later."
		/>
	) : (
		<PageError
			alertVariant="error"
			title="We couldn't process your request at this time."
			subtitle="Please try again later."
			hasContactUs
		/>
	);
