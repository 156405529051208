import { Color } from 'cymantic-ui';
import {
	highRelativityValue,
	minimumBubbleSizeForText
} from 'routes/SearchPage/ClusterView/ClusterView.definitions';

type ColorSettings = {
	textColor: Color;
	badgeColor: Color;
};

const colorOptions: Record<string, ColorSettings> = {
	highScore: {
		textColor: 'white',
		badgeColor: 'purple900'
	},
	mediumScore: {
		textColor: 'white',
		badgeColor: 'purple600'
	},
	lowScore: {
		textColor: 'grey900',
		badgeColor: 'purple200'
	},
	default: {
		textColor: 'grey900',
		badgeColor: 'grey300'
	}
};

export const getBadgeColor = (score: number): ColorSettings => {
	if (score >= highRelativityValue) {
		return colorOptions.highScore;
	}
	if (score > minimumBubbleSizeForText) {
		return colorOptions.mediumScore;
	}
	if (score > 25) {
		return colorOptions.lowScore;
	}
	return colorOptions.default;
};

export const convertScoreToPercentage = (score: number) => `${Math.round(score)}%`;
