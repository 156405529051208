import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

interface SerializedError {
	name?: string;
	message?: string;
	stack?: string;
	code?: string;
}

export type RTKQueryError = FetchBaseQueryError | SerializedError;

export const checkIsErrorCode = (status: number, error?: RTKQueryError) => {
	return error && 'status' in error && error?.status === status;
};
