import { TokenCheck } from 'redux/features/auth/auth.definitions';
import { ruthiApiBase } from 'redux/ruthi-api-base';

export const authQueries = ruthiApiBase.injectEndpoints({
	endpoints: (builder) => ({
		checkToken: builder.query<TokenCheck, void>({
			query: () => 'token'
		})
	})
});

export const { useCheckTokenQuery, useLazyCheckTokenQuery } = authQueries;
