import Footer from 'components/Footer/Footer';
import HeaderHome from 'components/HeaderHome/HeaderHome';
import { Outlet } from 'react-router-dom';
import { container, content } from 'routes/CenteredPageLayout/CenteredPageLayout.styles';

const CenteredPageLayout = () => (
	<div className={container}>
		<HeaderHome />
		<div className={content}>
			<Outlet />
		</div>
		<Footer />
	</div>
);

export default CenteredPageLayout;
