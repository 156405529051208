import { FlexBox, SkeletonAreaLoader } from 'cymantic-ui/dist/atomic-components';

export const ListViewLoader = () => {
	return (
		<FlexBox direction="column" width="100%" gap="space16" padding="space20">
			<SkeletonAreaLoader width="100%" height="5.25rem" />
			<SkeletonAreaLoader width="100%" height="5.25rem" />
			<SkeletonAreaLoader width="100%" height="5.25rem" />
			<SkeletonAreaLoader width="100%" height="5.25rem" />
			<SkeletonAreaLoader width="100%" height="5.25rem" />
			<SkeletonAreaLoader width="100%" height="5.25rem" />
			<SkeletonAreaLoader width="100%" height="5.25rem" />
			<SkeletonAreaLoader width="100%" height="5.25rem" />
			<SkeletonAreaLoader width="100%" height="5.25rem" />
			<SkeletonAreaLoader width="100%" height="5.25rem" />
		</FlexBox>
	);
};
