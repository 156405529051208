import { checkIsUnauthorized, Unauthorized } from 'components/Auth/Unauthorized';
import { AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { useGetFolderQuery } from 'redux/features/library';
import {
	LibraryFolderContent,
	LibraryFolderEmpty,
	LibraryFolderSkeleton
} from 'routes/Library/LibraryFolderPage/LibraryFolderStates';
import { LayoutHeaderBar } from 'routes/Library/LibraryHeader';
import { LibraryLayout } from 'routes/Library/LibraryLayout';
import { LibraryPageError } from 'routes/Library/LibraryPageError';
import { PageTransition } from 'routes/Library/LibraryTransition';

/**
 * Folder states:
 * 1. @isLoading
 * 2. @isEmpty Loads "This folder is empty" page - this will occur after delete file and add folder functionality is implemented
 * 3. @hasError (all errors except isEmpty): Loads full page error
 * 4. @hasData Loads page
 * 5. @isUnauthorized Redirects to signout/expired
 */
const LibraryFolderPage = () => {
	const { folderId } = useParams();
	const { data, isLoading, isError, isSuccess, error } = useGetFolderQuery(folderId ?? '');

	// Page states
	const hasData = isSuccess && !!data && (!!data?.items.length || !!data?.file_count);
	const isEmpty = isSuccess && !data?.items.length && !data?.file_count;
	const hasHeader = isLoading || hasData;

	// Library header info
	const pageTitle = data?.name ?? 'Folder';
	const parentFolderName = data?.parent_folder_name ? data.parent_folder_name : 'Library';
	const parentFolderLink = data?.parent_folder_id
		? `/library/folder/${data.parent_folder_id}`
		: '/library';

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	return (
		<LibraryLayout>
			{hasHeader && (
				<LayoutHeaderBar
					icon="Folder"
					title={pageTitle}
					backToLabel={parentFolderName}
					backTo={parentFolderLink}
					isLoading={isLoading}
				/>
			)}
			<AnimatePresence>
				{isLoading && (
					<PageTransition key="folderLoader" hasHeader={hasHeader}>
						<LibraryFolderSkeleton />
					</PageTransition>
				)}

				{/* NOTE: isEmpty shouldn't occur yet, in future when we add create folder & delete file abilities this could occur */}
				{isEmpty && (
					<PageTransition key="folderEmpty" delay={0.3} hasHeader={hasHeader}>
						<LibraryFolderEmpty />
					</PageTransition>
				)}
				{isError && (
					<PageTransition key="folderError" delay={0.3} hasHeader={hasHeader}>
						<LibraryPageError />
					</PageTransition>
				)}
				{hasData && (
					<PageTransition key="folderContent" delay={0.3} hasHeader={hasHeader}>
						<LibraryFolderContent data={data} folderId={folderId ?? ''} />
					</PageTransition>
				)}
			</AnimatePresence>
		</LibraryLayout>
	);
};

export default LibraryFolderPage;
