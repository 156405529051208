import * as CognitoHelpers from 'components/Auth/CognitoHelpers';
import { signInSignUpButton } from 'components/HeaderHome/HeaderHome.styles';
import { MainNav, MobileMenu, NavButton, NavLink, NavList, useMediaQueries } from 'cymantic-ui';
import Cookies from 'js-cookie';
import { getFeatureFlags } from 'utilities/get-feature-flags';

const SignInSignUpButton = () => {
	const hasSignedIn = Cookies.get('hasSignedIn') === 'true';

	return (
		<div className={signInSignUpButton}>
			{!hasSignedIn && (
				<NavButton label="Sign Up" onClick={CognitoHelpers.handleRedirectToSignUp} />
			)}
			{hasSignedIn && (
				<NavButton label="Sign In" onClick={CognitoHelpers.handleRedirectToSignIn} />
			)}
		</div>
	);
};

const HeaderHome = () => {
	const { isDiscLabellingDisabled } = getFeatureFlags();
	const { isMobile } = useMediaQueries();
	const isMobileMenu = isMobile;

	return (
		<MainNav product={isDiscLabellingDisabled ? 'Ruthi' : 'Discover'}>
			{isMobileMenu ? (
				<MobileMenu>
					<NavLink label="About" href="https://www.cymantix.com" />
					<NavLink label="Contact" href="mailto:support@cymantix.com" />
					<SignInSignUpButton />
				</MobileMenu>
			) : (
				<NavList ariaLabel="Links" gap="space48">
					<NavLink label="About" href="https://www.cymantix.com" isBlank />
					<NavLink label="Contact" href="mailto:support@cymantix.com" />
					<SignInSignUpButton />
				</NavList>
			)}
		</MainNav>
	);
};

export default HeaderHome;
