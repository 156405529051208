import { SignJWT } from 'jose';
import { v4 as uuidv4 } from 'uuid';

/**
 * Generate a new request id and corresponding JWT token to sign requests.
 * @returns {string, string} - Request ID and JWT token.
 */
export async function generateRequestSignature() {
	const secret = new TextEncoder().encode(process.env.REACT_APP_JWT_SECRET);

	// generate a random request id for signing
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const requestId = uuidv4();

	// generate a token with 1 min shelf life for request
	const content = {
		requestId
	};
	const token = await new SignJWT(content)
		.setProtectedHeader({ alg: 'HS256' })
		.setIssuedAt()
		.setExpirationTime('1m')
		.sign(secret);

	return {
		requestId,
		token
	};
}
