import { checkIsUnauthorized, Unauthorized } from 'components/Auth/Unauthorized';
import { Tree, TreeProps } from 'components/LibraryTree/Tree/Tree';
import { INode, MutableTreeState } from 'components/LibraryTree/Tree/Tree.definitions';
import * as React from 'react';
import { useGetFoldersQuery } from 'redux/features/library';
import { useAppSelector } from 'redux/hooks';

function LibraryTree() {
	const folders = useAppSelector((state) => state.library.folders);

	const { data, error } = useGetFoldersQuery(null, { skip: !folders });

	const userFolders = folders || data || [];

	const root: INode[] = [
		{
			id: '0',
			// Root folder is always "Library"
			name: 'Library',
			items: userFolders
		}
	];

	// Retrieves which nodes are expanded and selected from local storage
	const treeState: TreeProps['treeState'] = React.useMemo(() => {
		const localStorageTreeState = localStorage.getItem('tree-state');
		const tree: MutableTreeState | null = localStorageTreeState
			? JSON.parse(localStorageTreeState)
			: null;

		if (!tree) {
			return { selected: [0], expanded: [] };
		}

		return {
			selected: Array.isArray(tree.selected) ? tree.selected : tree.selected.path,
			expanded: tree.expanded
		};
	}, []);

	// Sets which nodes are expanded and selected in local storage
	const persistTreeState: TreeProps['persistTreeState'] = React.useCallback((state) => {
		const { expanded, selected } = state;

		localStorage.setItem(
			'tree-state',
			JSON.stringify({
				expanded,
				selected: Array.isArray(selected) ? selected : selected.path
			})
		);
	}, []);

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	return <Tree root={root} treeState={treeState} persistTreeState={persistTreeState} />;
}

export default LibraryTree;
