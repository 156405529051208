import { getUserDataFromToken } from 'components/Auth/CognitoHelpers';
import { PageHeader, PageHeaderLeft } from 'components/PageHeader/PageHeader';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import { format } from 'date-fns';
import { dashboardPage } from 'routes/DashboardPage/DashboardPage.styles';
import { JoinMeetingCard } from 'routes/DashboardPage/JoinMeetingCard/JoinMeetingCard';
import { MeetingEndedCard } from 'routes/DashboardPage/meeting-ended-card/meeting-ended-card';
import { RecentFilesCard } from 'routes/DashboardPage/RecentFilesCard/RecentFilesCard';
import { StartMeetingCard } from 'routes/DashboardPage/StartMeetingCard/StartMeetingCard';
import { getFeatureFlags } from 'utilities/get-feature-flags';

const DashboardPage = () => {
	const { name } = getUserDataFromToken();

	const currentDate = new Date();
	const formattedDate = format(currentDate, 'EEEE, MMMM do');
	const { isShannonEnabled, isLibraryEnabled } = getFeatureFlags();

	return (
		<>
			<PageHeader>
				<PageHeaderLeft title="Dashboard" />
			</PageHeader>
			<div className={dashboardPage.main}>
				<div className={dashboardPage.titleContainer}>
					<Text tag="h1" variant="headerH3">
						Hello {name.split(' ')[0]}!
					</Text>

					<Text variant="bodyXS" color="grey700">
						Welcome back, today is <Text isBold>{formattedDate}</Text>.
					</Text>
				</div>

				{isShannonEnabled && <MeetingEndedCard />}

				{isShannonEnabled && (
					<div className={dashboardPage.cardContainer}>
						<StartMeetingCard />
						<JoinMeetingCard />
					</div>
				)}

				{isLibraryEnabled && <RecentFilesCard />}
			</div>
		</>
	);
};

export default DashboardPage;
