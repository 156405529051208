import { AuthLoader } from 'components/Auth/AuthLoader';
import {
	checkTokenExists,
	getUserDataFromToken,
	handleSignInFlow,
	handleSignOutFlow,
	retrieveAuthCodeFromUrl
} from 'components/Auth/CognitoHelpers';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { setSignedIn, setUserData, useLazyCheckTokenQuery } from 'redux/features/auth';
import { useAppDispatch } from 'redux/hooks';
import { getFeatureFlags } from 'utilities/get-feature-flags';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate();
	const [isSigningIn, setIsSigningIn] = React.useState(false);
	const { isAuthEnabled } = getFeatureFlags();

	const [
		checkToken,
		{ data, isLoading: isTokenLoading, isSuccess: isTokenSuccess, isError: isTokenError }
	] = useLazyCheckTokenQuery();

	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (isSigningIn) {
			return;
		}

		// CASE 1: REDIRECTED FROM SIGN-IN
		const authCode = retrieveAuthCodeFromUrl();
		const isRedirectedFromSignIn = !!authCode;

		if (isRedirectedFromSignIn) {
			setIsSigningIn(true);
			handleSignInFlow()
				.then(() => {
					setIsSigningIn(false);
					navigate('/');
				})
				.catch(() => {
					setIsSigningIn(false);
					navigate('/signout/invalid');
				});
			return;
		}

		// CASE 2: REDIRECTED FROM SIGN-OUT
		const isRedirectedFromSignOut = Cookies.get('CognitoSigningOutRedirection') === 'true';
		if (isRedirectedFromSignOut) {
			handleSignOutFlow();
			return;
		}

		// CASE 3: ALREADY SIGNED IN
		const hasToken = checkTokenExists();

		if (hasToken && isAuthEnabled) {
			checkToken();
		}
	}, [isSigningIn, navigate, dispatch, checkToken, isAuthEnabled]);

	React.useEffect(() => {
		if (isTokenSuccess) {
			const userData = getUserDataFromToken();
			dispatch(setUserData({ ...userData, urlKey: data?.urlKey }));
			dispatch(setSignedIn(true));
		}
		if (isTokenError) {
			handleSignOutFlow();
			navigate('/signout/unknown');
		}
	}, [isTokenSuccess, isTokenError, dispatch, navigate, data?.urlKey]);

	const isLoaded = !isSigningIn && !isTokenLoading;

	return (
		<>
			{isSigningIn && <AuthLoader>Signing in...</AuthLoader>}
			{isTokenLoading && <AuthLoader>Loading...</AuthLoader>}
			{isLoaded && children}
		</>
	);
};

export default AuthProvider;
