import {
	PageHeader,
	PageHeaderLeft,
	PageHeaderLeftSkeleton
} from 'components/PageHeader/PageHeader';
import { IconName } from 'cymantic-ui/dist/atomic-components';
import { AnimatePresence } from 'framer-motion';
import { libraryLayoutHeader } from 'routes/Library/LibraryLayout.styles';
import { PageTransition } from 'routes/Library/LibraryTransition';

type LayoutHeaderBarProps = {
	backToLabel?: string;
	icon?: IconName;
	title: string;
	backTo?: string;
	isLoading?: boolean;
};

export const LayoutHeaderBar = ({
	title,
	backToLabel,
	backTo,
	icon,
	isLoading
}: LayoutHeaderBarProps) => {
	const hasBackLink = !!backTo && !!backToLabel;

	return (
		<PageHeader>
			<AnimatePresence>
				{isLoading && (
					<PageTransition key="headerLoader" className={libraryLayoutHeader.left}>
						<PageHeaderLeftSkeleton hasBackLink={hasBackLink} hasTitleIcon={!!icon} />
					</PageTransition>
				)}
				{!isLoading && (
					<PageTransition
						key="headerContent"
						delay={0.3}
						className={libraryLayoutHeader.left}
					>
						<PageHeaderLeft
							backTo={backTo}
							backToLabel={backToLabel}
							icon={icon}
							title={title}
						/>
					</PageTransition>
				)}
			</AnimatePresence>
		</PageHeader>
	);
};
