import { alertVariants } from 'components/PageError/PageError';
import { pageSectionError } from 'components/PageSectionError/PageSectionError.styles';
import { AlertVariant } from 'cymantic-ui/dist/atomic-components/alert';
import { Icon } from 'cymantic-ui/dist/atomic-components/icon';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import * as React from 'react';

type PageSectionErrorProps = {
	alertVariant?: AlertVariant;
	title?: string;
	subtitle?: string;
	children?: React.ReactNode;
};

/**
 * @param alertVariant (OPTIONAL) The type of alert to display
 * @param title A friendly non-technical title for the error
 * @param children Actions the user can take (EX: retry, return to home, etc)
 */
export const PageSectionError = ({
	title,
	children,
	alertVariant,
	subtitle
}: PageSectionErrorProps) => (
	<div className={pageSectionError.root}>
		<div className={pageSectionError.title}>
			{alertVariant && (
				<Icon
					icon={alertVariants[alertVariant].iconName}
					color={alertVariants[alertVariant].color}
					size="space24"
				/>
			)}
			{title && (
				<Text tag="div" variant="bodySM" isBold textAlign="center">
					{title}
				</Text>
			)}
			{subtitle && (
				<Text tag="div" variant="bodyXS" color="grey700" textAlign="center">
					{subtitle}
				</Text>
			)}
		</div>
		{children}
	</div>
);
