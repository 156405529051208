import { IconButtonWithTooltip } from 'cymantic-ui/dist/atomic-components/icon-button-with-tooltip';
import { useUrlSearchPage } from 'hooks/use-url-search-page';
import { useAppSelector } from 'redux/hooks';

type ZoomOutButtonProps = {
	isDisabled: boolean;
};

export const ZoomOutButton = ({ isDisabled }: ZoomOutButtonProps) => {
	const { handleClusterSearch, urlInput } = useUrlSearchPage();
	const cachedVis = useAppSelector((s) => s.cluster.cachedVis);
	const clusterQuery = useAppSelector((s) => s.search.clusterQuery);

	const lens = clusterQuery?.lens;
	const index = clusterQuery?.index;
	const query = clusterQuery?.query;
	const file = clusterQuery?.file;

	const isZoomOutFromBasket = urlInput.depth > 2;

	const handleZoomOut = () => {
		if (isZoomOutFromBasket) {
			const previousVis = cachedVis[urlInput.depth - 1];

			const obj = {
				selectedMemberDocs: previousVis,
				lens,
				index,
				query,
				file,
				depth: urlInput.depth - 1
			};

			handleClusterSearch(obj, 'zoomOutBasket');
		} else {
			handleClusterSearch({ lens, index, query, file }, 'zoomOut');
		}
	};

	return (
		<IconButtonWithTooltip
			tooltip="Zoom Out from Clusters"
			icon="ZoomOut"
			variant="grey"
			subVariant="outline"
			onClick={handleZoomOut}
			ariaLabel="Zoom Out"
			size="md"
			disabled={isDisabled}
		/>
	);
};
