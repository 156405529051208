const isFlagOn = (flag?: string) => {
	return !!flag && flag.toLowerCase() === 'true';
};

export function getFeatureFlags() {
	const authFlag = process.env.REACT_APP_AUTHENTICATION_FLAG;
	const libraryFlag = process.env.REACT_APP_LIBRARY_FLAG;
	const shannonFlag = process.env.REACT_APP_SHANNON_FLAG;
	const noBrandLabellingFlag = process.env.REACT_APP_NO_DISCOVER_LABELLING;

	const featureFlags = {
		isAuthEnabled: isFlagOn(authFlag),
		isLibraryEnabled: isFlagOn(libraryFlag),
		isShannonEnabled: isFlagOn(shannonFlag),
		isDiscLabellingDisabled: isFlagOn(noBrandLabellingFlag)
	};

	return featureFlags;
}
