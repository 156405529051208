import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authReducer } from 'redux/features/auth';
import { clusterReducer } from 'redux/features/cluster';
import { libraryReducer } from 'redux/features/library';
import { searchReducer } from 'redux/features/search';
import { toastReducer } from 'redux/features/toast/toast-slice';
import { meetApiBase } from 'redux/meet-api-base';
import { ruthiApiBase } from 'redux/ruthi-api-base';

const store = configureStore({
	reducer: {
		library: libraryReducer,
		search: searchReducer,
		cluster: clusterReducer,
		auth: authReducer,
		toast: toastReducer,
		[ruthiApiBase.reducerPath]: ruthiApiBase.reducer,
		[meetApiBase.reducerPath]: meetApiBase.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([ruthiApiBase.middleware, meetApiBase.middleware])
});

setupListeners(store.dispatch);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
export type AppDispatch = typeof store.dispatch;
