import { checkIsUnauthorized, Unauthorized } from 'components/Auth/Unauthorized';
import { AnimatePresence } from 'framer-motion';
import { useGetFolderQuery } from 'redux/features/library';
import { LayoutHeaderBar } from 'routes/Library/LibraryHeader';
import { LibraryLayout } from 'routes/Library/LibraryLayout';
import {
	LibraryPageContent,
	LibraryPageEmpty,
	LibraryPageSkeleton
} from 'routes/Library/LibraryPage/LibraryPageStates';
import { LibraryPageError } from 'routes/Library/LibraryPageError';
import { PageTransition } from 'routes/Library/LibraryTransition';

/**
 * Library states:
 * 1. @isLoading
 * 2. @isEmpty Loads "No Shannon Transcripts" page
 * 3. @hasError (all errors except isEmpty): Loads full page error
 * 4. @hasData Loads page
 * 5. @isUnauthorized Redirects to signout/expired
 */
const LibraryPage = () => {
	const { data, isError, isLoading, isSuccess, error } = useGetFolderQuery('');
	const hasData = isSuccess && !!data && (!!data?.items.length || !!data?.file_count);
	const isEmpty = isSuccess && !data?.items.length && !data?.file_count;

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	return (
		<LibraryLayout>
			<LayoutHeaderBar title="Library" />

			<AnimatePresence>
				{isLoading && (
					<PageTransition key="librarySkeleton" hasHeader>
						<LibraryPageSkeleton />
					</PageTransition>
				)}
				{isEmpty && (
					<PageTransition key="libraryEmpty" delay={0.3} hasHeader>
						<LibraryPageEmpty />
					</PageTransition>
				)}
				{isError && (
					<PageTransition key="libraryError" delay={0.3} hasHeader>
						<LibraryPageError />
					</PageTransition>
				)}
				{hasData && (
					<PageTransition key="libraryContent" delay={0.3} hasHeader>
						<LibraryPageContent data={data} />
					</PageTransition>
				)}
			</AnimatePresence>
		</LibraryLayout>
	);
};

export default LibraryPage;
