import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TranscriptFilesResponse } from 'redux/features/library';
import { tableColumns } from 'routes/Library/LibraryTable/LibraryTable.definitions';
import { libraryTable } from 'routes/Library/LibraryTable/LibraryTable.styles';

type LibraryFilesTableProps = {
	data: TranscriptFilesResponse[];
};

export const LibraryFilesTable = ({ data }: LibraryFilesTableProps) => {
	const table = useReactTable({
		columns: tableColumns,
		data,
		getCoreRowModel: getCoreRowModel()
	});

	return (
		<table className={libraryTable}>
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<th key={header.id}>
								{header.isPlaceholder
									? null
									: flexRender(
											header.column.columnDef.header,
											header.getContext()
									  )}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id}>
						{row.getVisibleCells().map((cell) => (
							<td key={cell.id}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};
