import { DocumentSummary, DocumentSummaryInput } from 'redux/features/summary/summary.definitions';
import { ruthiApiBase } from 'redux/ruthi-api-base';

export const summaryQueries = ruthiApiBase.injectEndpoints({
	endpoints: (builder) => ({
		getDocumentSummary: builder.query<DocumentSummary, DocumentSummaryInput>({
			query: ({ index, lens, documentId, type }) => ({
				url: `docSummary/${type}`,
				method: 'POST',
				body: JSON.stringify({
					index,
					lens,
					document_id: documentId
				})
			})
		})
	})
});

export const { useGetDocumentSummaryQuery, useLazyGetDocumentSummaryQuery } = summaryQueries;
