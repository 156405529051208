import { theme } from 'cymantic-ui/dist/theme';

export const diameter = 800;
export const svgPadding = 42;
export const bubbleMargin = 12;
export const minimumBubbleSize = 5;
export const minimumBubbleSizeForText = 50;
export const maximumBubbleSize = 100;
export const containerClass = '.cluster-visualization';
export const labelLineHeight = 1.5;

export const highRelativityValue = 76;

export const lightOutlineColor = theme.color.primary900;
export const darkOutlineColor = '#373AD7';

export const basketStrokeWidth = 4;
export const defaultStrokeWidth = 0;
