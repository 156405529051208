import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const actionCard = {
	root: css`
		background-color: ${theme.color.white};
		border-radius: ${theme.borderRadius.radiusMD};
		border-style: solid;
		border-width: 1px;
		display: flex;
	`,
	left: css`
		align-items: center;
		border-bottom-left-radius: ${theme.borderRadius.radiusMD};
		border-top-left-radius: ${theme.borderRadius.radiusMD};
		display: flex;
		justify-content: center;
		padding: ${theme.space.space24};
	`,
	right: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space24};
		padding: ${theme.space.space24};
		width: 100%;
	`,
	content: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space16};
		width: 100%;
	`,
	close: css`
		position: absolute;
		right: ${theme.space.space24};
		top: ${theme.space.space24};
	`
};
