import { css } from '@emotion/css';
import { FolderLinkVariant } from 'components/FolderLink/FolderLink.definitions';
import { theme } from 'cymantic-ui/dist/theme';

type FolderLinkStyles = {
	root: string;
	icon: string;
	iconVariant: Record<FolderLinkVariant, string>;
};

export const folderLink: FolderLinkStyles = {
	root: css`
		align-items: center;
		background-color: ${theme.color.white};
		border: 1px solid ${theme.color.grey300};
		border-radius: ${theme.borderRadius.radiusSM};
		display: flex;
		gap: ${theme.space.space8};
		padding: ${theme.space.space8};
		padding-right: ${theme.space.space16};
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: ${theme.color.grey100};
		}
	`,
	icon: css`
		border-radius: 3rem;
		padding: ${theme.space.space8};
	`,
	iconVariant: {
		primary: css`
			background-color: ${theme.color.primary100};
			color: ${theme.color.primary800};
		`,
		secondary: css`
			background-color: ${theme.color.grey100};
			color: ${theme.color.grey800};
		`
	}
};
