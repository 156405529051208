import { SkeletonTextLoader } from 'cymantic-ui/dist/atomic-components/skeleton-loader';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import { TranscriptItem } from 'routes/Library/TranscriptFilePage/TranscriptFilePage.definitions';
import { transcriptRow } from 'routes/Library/TranscriptFilePage/TranscriptFilePage.styles';
import { getDurationFromUnix } from 'utilities/time-formatting';

type TranscriptRowProps = Omit<TranscriptItem, 'speakerId' | 'corrected'> & {
	firstTimestamp: number;
	hasHours?: boolean;
};

export const TranscriptRow = ({
	speakerName,
	timestamp,
	firstTimestamp,
	text,
	hasHours
}: TranscriptRowProps) => {
	const { hours, minutes, seconds } = getDurationFromUnix(firstTimestamp, timestamp);
	const zeroPad = (num: number) => String(num).padStart(2, '0');
	const hoursString = hasHours && hours ? `${zeroPad(hours)}:` : '';
	const minutesString = minutes ? `${zeroPad(minutes)}:` : '00:';
	const secondsString = seconds ? `${zeroPad(seconds)}` : '00';
	const formattedTime = `${hoursString}${minutesString}${secondsString}`;

	return (
		<li className={transcriptRow.root}>
			<Text variant="bodyXS" color="grey500" textAlign="right">
				{formattedTime}
			</Text>
			<Text variant="bodyXS" isBold>
				{speakerName}
			</Text>
			<Text variant="bodyXS" color="grey800">
				{text}
			</Text>
		</li>
	);
};

export const TranscriptRowSkeleton = () => {
	const randomWidth = Math.floor(Math.random() * 100) + 1;
	return (
		<div className={transcriptRow.root}>
			<SkeletonTextLoader variant="bodyXS" width="75px" />
			<SkeletonTextLoader variant="bodyXS" width="150px" />
			<SkeletonTextLoader variant="bodyXS" width={`${randomWidth}%`} />
		</div>
	);
};
