import { rootStyles } from 'components/LibraryTree/Tree/Tree.styles';
import * as React from 'react';

export type RootProps = React.OlHTMLAttributes<HTMLUListElement>;

export const RootNode = React.forwardRef<HTMLUListElement, RootProps>(
	({ children, ...rest }, ref) => (
		<ul className={rootStyles.root} ref={ref} role="tree" {...rest}>
			{children}
		</ul>
	)
);
