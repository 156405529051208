import { PageHeader, PageHeaderLeftSkeleton } from 'components/PageHeader/PageHeader';
import { pageHeader } from 'components/PageHeader/PageHeader.styles';
import { Badge, DateFormat, FlexBox, RouterLink, Text } from 'cymantic-ui/dist/atomic-components';
import { AnimatePresence } from 'framer-motion';
import { DateObjectModel } from 'redux/features/cluster';
import { libraryLayoutHeader } from 'routes/Library/LibraryLayout.styles';
import { PageTransition } from 'routes/Library/LibraryTransition';

type DetailPageHeaderProps = {
	backToLabel?: string;
	title: string;
	backTo?: string;
	isLoading?: boolean;
	date?: DateObjectModel;
	badgeTitle?: string;
};

export const DetailPageHeader = ({
	title,
	backToLabel,
	backTo,
	isLoading,
	date,
	badgeTitle
}: DetailPageHeaderProps) => {
	const hasBackLink = !!backTo && !!backToLabel;

	return (
		<PageHeader>
			<AnimatePresence>
				{isLoading && (
					<PageTransition key="headerLoader" className={libraryLayoutHeader.left}>
						<PageHeaderLeftSkeleton hasBackLink={hasBackLink} />
					</PageTransition>
				)}
				{!isLoading && (
					<PageTransition
						key="headerContent"
						delay={0.3}
						className={libraryLayoutHeader.left}
					>
						<header className={pageHeader.left}>
							{backTo && backToLabel && (
								<RouterLink
									variant="bodyXXS"
									to={backTo}
									label={backToLabel}
									iconLeft="CornerLeftUp"
								/>
							)}
							<Text variant="bodyM" color="grey900" tag="h1">
								{title}
							</Text>
							<FlexBox direction="row" gap="space12">
								{badgeTitle ? (
									<Badge size="sm" backgroundColor="purple800" color="white">
										{badgeTitle}
									</Badge>
								) : null}
								{date ? (
									<DateFormat
										size="bodyXS"
										color="grey700"
										variant={date.displayType}
										timestamp={date.datetime}
									/>
								) : null}
							</FlexBox>
						</header>
					</PageTransition>
				)}
			</AnimatePresence>
		</PageHeader>
	);
};
