import { nodeGroupStyles } from 'components/LibraryTree/Tree/Tree.styles';
import { HTMLMotionProps, motion } from 'framer-motion';
import * as React from 'react';

type NodeGroupProps = HTMLMotionProps<'ul'>;

const groupMotion: Pick<HTMLMotionProps<'ul'>, 'initial' | 'animate' | 'exit' | 'transition'> = {
	initial: { maxHeight: 0, overflow: 'hidden' },
	// TODO: Find an alternative to a hard-coded value, this will eventually break things.
	// Could somehow get a count of all possible children and have a calculation based on that.
	animate: { maxHeight: '2000px', transitionEnd: { overflow: 'visible' } },
	exit: { maxHeight: 0, overflow: 'hidden' },
	transition: { type: 'tween', duration: 0.6 }
};

export const NodeGroup = React.forwardRef<HTMLUListElement, NodeGroupProps>(
	({ children, ...rest }, ref) => (
		<motion.ul
			{...groupMotion}
			className={nodeGroupStyles.group}
			ref={ref}
			role="group"
			{...rest}
		>
			{children}
		</motion.ul>
	)
);
