import { actionCard } from 'components/action-card/action-card.styles';
import {
	AlertVariant,
	CloseButton,
	Icon,
	IconName,
	Text
} from 'cymantic-ui/dist/atomic-components';
import { Color, theme } from 'cymantic-ui/dist/theme';
import * as React from 'react';

type ActionCardProps = {
	variant: AlertVariant;
	icon: IconName;
	title: string;
	description?: string;
	children: React.ReactNode;
	onClose?: () => void;
};

type ActionCardVariantProps = {
	mainColor: Color;
	secondaryColor: Color;
};

const actionCardVariants: Record<AlertVariant, ActionCardVariantProps> = {
	info: {
		mainColor: 'blue600',
		secondaryColor: 'blue50'
	},
	success: {
		mainColor: 'green600',
		secondaryColor: 'green50'
	},
	warning: {
		mainColor: 'yellow600',
		secondaryColor: 'yellow50'
	},
	error: {
		mainColor: 'red600',
		secondaryColor: 'red50'
	}
};

export const ActionCard = ({
	icon,
	variant,
	title,
	children,
	description,
	onClose
}: ActionCardProps) => {
	const { mainColor } = actionCardVariants[variant];
	const backgroundColor = theme.color[actionCardVariants[variant].secondaryColor];
	const borderColor = theme.color[actionCardVariants[variant].mainColor];

	return (
		<section className={actionCard.root} style={{ borderColor }}>
			<div className={actionCard.left} style={{ backgroundColor }}>
				<Icon icon={icon} size="space40" color={mainColor} />
			</div>
			<div className={actionCard.right}>
				<div className={actionCard.content}>
					<Text tag="h2" variant="headerH4" color="grey800">
						{title}
					</Text>
					{description && (
						<Text variant="bodyXS" color="grey600">
							{description}
						</Text>
					)}
				</div>
				<div>{children}</div>
				{onClose && (
					<div className={actionCard.close}>
						<CloseButton size="md" ariaLabel="Close section" onClick={onClose} />
					</div>
				)}
			</div>
		</section>
	);
};
