import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

const sharedStyles = css`
	border-radius: ${theme.borderRadius.radiusMD};
	display: block;
	padding: 0.875rem;
	transition: background-color 0.2s ease-in-out;
`;

export const sidebarLink = {
	root: css`
		${sharedStyles}
		color: ${theme.color.grey700};

		&:link,
		&:visited,
		&:active {
			color: ${theme.color.grey700};
		}

		&:hover {
			background-color: ${theme.color.primary50};
			color: ${theme.color.primary900};
		}
	`,
	active: css`
		${sharedStyles}
		background-color: ${theme.color.primary50};

		&:link,
		&:visited,
		&:active,
		&:hover {
			color: ${theme.color.primary900};
		}

		&:hover {
			background-color: ${theme.color.primary100};
		}
	`
};
