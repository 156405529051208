import { PageSectionError } from 'components/PageSectionError/PageSectionError';
import { SkeletonAreaLoader } from 'cymantic-ui';

export const TableError = () => (
	<PageSectionError
		title="An error occurred while retrieving your files"
		subtitle="Please try again later."
		alertVariant="error"
	/>
);
type TableNoDataProps = {
	message: string;
};

export const TableNoData = ({ message }: TableNoDataProps) => (
	<PageSectionError subtitle={message} />
);

export const TableSkeleton = () => (
	<SkeletonAreaLoader variant="rounded" height="606px" width="100%" />
);
