import { css } from '@emotion/css';
import { theme, Toast } from 'cymantic-ui';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppSelector } from 'redux/hooks';

export const container = css`
	position: fixed;
	z-index: 9999;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	gap: ${theme.space.space8};
	padding: ${theme.space.space24} ${theme.space.space32};
`;

const initialPosition = css({ position: 'relative', right: '-75px', scaleX: 0.1, opacity: 0 });

const toastDisplay = css({ position: 'relative', right: 0, scaleX: 1, opacity: 1 });

const toastTransition = {
	type: 'spring',
	stuffness: 300,
	damping: 20
};

export const ToastNotifications = () => {
	const toasts = useAppSelector((state) => state.toast);
	return (
		<div className={container}>
			<AnimatePresence>
				{toasts.map(({ id, ...rest }) => (
					<motion.div
						key={id}
						initial={initialPosition}
						animate={toastDisplay}
						transition={toastTransition}
						exit={{
							opacity: 0
						}}
					>
						<Toast key={id} {...rest} />
					</motion.div>
				))}
			</AnimatePresence>
		</div>
	);
};
