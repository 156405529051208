import AuthWrapper from 'components/Auth/AuthWrapper';
import { PrivacyPolicy, Terms } from 'cymantic-ui/dist/molecular-components/agreements';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CenteredPageLayout from 'routes/CenteredPageLayout/CenteredPageLayout';
import DashboardPage from 'routes/DashboardPage/DashboardPage';
import FailedAuthPage from 'routes/FailedAuthPage/FailedAuthPage';
import HomePage from 'routes/HomePage/HomePage';
import { LeftSidebarPageLayout } from 'routes/left-sidebar-page-layout';
import LibraryFolderPage from 'routes/Library/LibraryFolderPage/LibraryFolderPage';
import LibraryPage from 'routes/Library/LibraryPage/LibraryPage';
import TranscriptFilePage from 'routes/Library/TranscriptFilePage/TranscriptFilePage';
import NoMatchPage from 'routes/NoMatchPage/NoMatchPage';
import Protected from 'routes/Protected/Protected';
import { SearchPage } from 'routes/SearchPage/SearchPage';
import { getFeatureFlags } from 'utilities/get-feature-flags';

const App = () => {
	const { isShannonEnabled, isLibraryEnabled, isDiscLabellingDisabled } = getFeatureFlags();

	if (isDiscLabellingDisabled) {
		document.title = 'Cymantix - See the big picture in you data';
	}

	return (
		<BrowserRouter>
			<AuthWrapper>
				<Routes>
					<Route path="/" element={<LeftSidebarPageLayout />}>
						{(isShannonEnabled || isLibraryEnabled) && (
							<Route
								path="/dashboard"
								element={
									<Protected>
										<DashboardPage />
									</Protected>
								}
							/>
						)}
						<Route
							path="/search"
							element={
								<Protected>
									<SearchPage />
								</Protected>
							}
						/>
						{isLibraryEnabled && (
							<>
								<Route
									path="/library"
									element={
										<Protected>
											<LibraryPage />
										</Protected>
									}
								/>
								<Route
									path="/library/folder/:folderId"
									element={
										<Protected>
											<LibraryFolderPage />
										</Protected>
									}
								/>
								{isShannonEnabled && (
									<Route
										path="/library/file/:fileType/:fileId"
										element={
											<Protected>
												<TranscriptFilePage />
											</Protected>
										}
									/>
								)}
							</>
						)}
					</Route>
					<Route path="/" element={<CenteredPageLayout />}>
						<Route index element={<HomePage />} />
						<Route path="/signout/:cause" element={<FailedAuthPage />} />
						<Route
							path="/terms"
							element={
								<Terms product={isDiscLabellingDisabled ? 'Ruthi' : 'DiscoverOS'} />
							}
						/>
						<Route path="/privacy" element={<PrivacyPolicy />} />
						<Route path="*" element={<NoMatchPage />} />
					</Route>
				</Routes>
			</AuthWrapper>
		</BrowserRouter>
	);
};

export default App;
