import { DatabaseLenses, Databases } from 'redux/features/search/search.definitions';
import { ruthiApiBase } from 'redux/ruthi-api-base';

export const searchQueries = ruthiApiBase.injectEndpoints({
	endpoints: (builder) => ({
		getDatabases: builder.query<Databases, void>({
			query: () => `databases`
		}),
		getDatabaseLenses: builder.query<DatabaseLenses, string>({
			query: (database) => ({
				url: `lenses`,
				method: 'POST',
				body: JSON.stringify({ index: database })
			})
		})
	})
});

export const {
	useGetDatabaseLensesQuery,
	useGetDatabasesQuery,
	useLazyGetDatabaseLensesQuery,
	useLazyGetDatabasesQuery
} = searchQueries;
