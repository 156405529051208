import { Navigate } from 'react-router-dom';
import { checkIsErrorCode, RTKQueryError } from 'redux/error-helpers';
import { getFeatureFlags } from 'utilities/get-feature-flags';

export const checkIsUnauthorized = (error?: RTKQueryError) => {
	const { isAuthEnabled } = getFeatureFlags();
	return checkIsErrorCode(401, error) && isAuthEnabled;
};

export const Unauthorized = () => {
	return <Navigate to="/signout/expired" />;
};
