import { Node } from 'components/LibraryTree/Tree/Node';
import { NodeGroup } from 'components/LibraryTree/Tree/NodeGroup';
import { RootNode, RootProps } from 'components/LibraryTree/Tree/Root';
import { INode, MutableTreeState, TreeNode } from 'components/LibraryTree/Tree/Tree.definitions';
import * as React from 'react';

export type TreeProps = {
	root: INode[];
	treeState: MutableTreeState;
	persistTreeState: (state: MutableTreeState) => void;
};

type RenderNodesProps = {
	nodes: INode['items'];
	parentNode: TreeNode;
	treeState: React.MutableRefObject<MutableTreeState>;
};

const RenderNodes = ({ nodes, parentNode, treeState }: RenderNodesProps) => {
	let previousNode: TreeNode | null;

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{nodes
				? nodes.map((node, index) => {
						const key = `${node.id}`;
						const path = [...parentNode.path, index];
						const currentNode: TreeNode = new TreeNode(path);

						const hasNodes = node?.items && node?.items.length > 0;
						currentNode.type = hasNodes ? 'node' : 'leaf';
						currentNode.id = node.id;
						currentNode.name = node.name;
						currentNode.parent = parentNode;

						if (!previousNode) {
							previousNode = currentNode;
						} else {
							previousNode.next = currentNode;
							currentNode.previous = previousNode;
							previousNode = currentNode;
						}
						return (
							<Node
								treeState={treeState}
								node={currentNode}
								aria-setsize={nodes.length}
								aria-posinset={index + 1}
								aria-level={path.length}
								key={key}
							>
								{hasNodes && (
									<NodeGroup>
										<RenderNodes
											nodes={node.items}
											treeState={treeState}
											parentNode={currentNode}
										/>
									</NodeGroup>
								)}
							</Node>
						);
				  })
				: null}
		</>
	);
};

export const Tree = React.forwardRef<HTMLUListElement, RootProps & TreeProps>(
	({ root, treeState, persistTreeState, ...rest }, ref) => {
		const state = React.useRef<MutableTreeState>({
			selected: treeState.selected,
			expanded: treeState.expanded
		});

		React.useEffect(() => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			return () => persistTreeState(state.current);
		}, [persistTreeState]);

		const rootNode = new TreeNode([]);

		return (
			<RootNode ref={ref} {...rest}>
				<RenderNodes nodes={root} parentNode={rootNode} treeState={state} />
			</RootNode>
		);
	}
);
