import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const docPanel = {
	root: css`
		background: ${theme.color.white};
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;
		width: 100%;
		z-index: 100;
	`,
	header: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space16};
		padding: ${theme.space.space24} ${theme.space.space20};
	`,
	headerInner: css`
		display: flex;
		gap: ${theme.space.space16};
		justify-content: space-between;
	`,
	headerLeft: css`
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: ${theme.space.space8};
		justify-content: space-between;
		width: 100%;
	`
};

export const docList = {
	root: css`
		height: 100%;
	`,
	skeleton: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space16};
		height: 76vh;
		padding: ${theme.space.space24} ${theme.space.space20};
	`,
	lazyLoader: css`
		height: 100%;
		width: 100%;
	`
};

export const docRow = {
	root: css`
		display: flex;
		flex-direction: row;
		height: auto;
		width: 100%;

		&:hover {
			background-color: ${theme.color.primary50};
		}
	`,
	info: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space4};
		padding: ${theme.space.space20};
		width: 90%;
	`
};
