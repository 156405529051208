import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const startMeetingCard = {
	buttons: css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: ${theme.space.space16};

		> button {
			flex: 1;
			min-width: 12rem;
		}
	`
};
