import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const relativity = {
	root: css`
		align-items: center;
		display: flex;
		gap: ${theme.space.space8};
	`,
	colorSwatch: css`
		border-radius: ${theme.borderRadius.radiusSM};
		height: 20px;
		width: 20px;
	`
};

export const relativityPanel = {
	root: css`
		background: ${theme.color.white};
		border: 1px solid ${theme.color.grey200};
		border-radius: ${theme.borderRadius.radiusMD};
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space16};
		max-width: 9rem;
		overflow: hidden;
		padding: ${theme.space.space12};
		z-index: 100;
	`
};
