import { footer } from 'components/Footer/Footer.styles';
import { RouterLink } from 'cymantic-ui/dist/atomic-components/link';
import { Text } from 'cymantic-ui/dist/atomic-components/text';

const Footer = () => {
	const yearOfCopyright = new Date().getFullYear();

	return (
		<div className={footer}>
			<Text variant="bodyXXS" color="grey500" tag="div">
				Copyright &copy; {yearOfCopyright} Cymantix Inc
			</Text>
			<Text variant="bodyXXS" color="grey500" tag="div">
				<RouterLink label="Terms of Use" to="/terms" /> &amp;{' '}
				<RouterLink label="Privacy" to="/privacy" />
			</Text>
		</div>
	);
};

export default Footer;
