import { motion } from 'framer-motion';

type OpacityTransitionProps = {
	children: React.ReactNode;
	duration?: number;
	delay?: number;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>;

/**
 * @use MUST BE USED AS DIRECT DESCENDANT OF <AnimatePresence> framer-motion component
 * @duration is set to 0.3s, add delay if needed to stagger exit/entrance animations
 */
export const OpacityTransition = ({
	children,
	className,
	duration = 0.3,
	delay = 0
}: OpacityTransitionProps) => {
	return (
		<motion.div
			className={className}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration, delay }}
		>
			{children}
		</motion.div>
	);
};
