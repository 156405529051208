import * as React from 'react';
import LazyLoadAutoSizer from 'react-virtualized-auto-sizer';
import { useDocPanel } from './doc-panel-provider';
import { useAppSelector } from '../../../redux/hooks';
import { VariableSizeList as LazyLoadList } from 'react-window';
import { DocRow } from './doc-row';
import { docList } from './doc-panel.styles';

export const DocList = () => {
	const searchInfo = useAppSelector((state) => state.search.clusterQuery);
	const lazyLoadListRef = React.useRef({});
	const lazyLoadRowHeights = React.useRef({});
	const { data: docData } = useDocPanel();

	React.useEffect(() => {
		if (lazyLoadListRef.current && lazyLoadListRef.current.scrollToItem) {
			lazyLoadListRef.current.scrollToItem(0);
		}
	}, [docData.selectedSortOption]);

	const getLazyLoadRowHeight = (lazyLoadRowIndex) =>
		lazyLoadRowHeights.current[lazyLoadRowIndex] + 8 || 82;

	const setLazyLoadRowHeight = React.useCallback((lazyLoadRowIndex, size) => {
		lazyLoadRowHeights.current = { ...lazyLoadRowHeights.current, [lazyLoadRowIndex]: size };
		lazyLoadListRef.current.resetAfterIndex(lazyLoadRowIndex);
	}, []);

	return (
		<div ref={lazyLoadListRef} className={docList.root}>
			<LazyLoadAutoSizer className={docList.lazyLoader}>
				{({ height, width }) => (
					<LazyLoadList
						height={height}
						itemCount={Object.keys(docData.filteredDocs).length}
						itemSize={getLazyLoadRowHeight}
						ref={lazyLoadListRef}
						width={width}
					>
						{({ index, style }) => {
							const [, data] = Object.entries(docData.filteredDocs)[index];

							return (
								<DocRow
									data={data}
									searchInfo={searchInfo}
									lazyLoadStyle={style}
									lazyLoadRowIndex={index}
									setLazyLoadRowHeight={setLazyLoadRowHeight}
								/>
							);
						}}
					</LazyLoadList>
				)}
			</LazyLoadAutoSizer>
		</div>
	);
};
