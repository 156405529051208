import { nodeLabelStyles } from 'components/LibraryTree/Tree/Tree.styles';
import { Icon, theme } from 'cymantic-ui';
import { motion } from 'framer-motion';
import * as React from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

interface INodeLabelProps extends React.LiHTMLAttributes<HTMLDivElement> {
	name: string;
	type: 'node' | 'leaf';
	to: NavLinkProps['to'];
	open?: boolean;
	insetFactor?: number;
}

const NodeIndicator = ({ open }: { open: boolean }) => (
	<motion.svg
		animate={{ rotate: open ? 90 : 0 }}
		className={nodeLabelStyles.indicator}
		viewBox="0 0 24 24"
		aria-hidden
	>
		<path className={nodeLabelStyles.indicatorPath} d="M8.625 5.25l6.75 6.75-6.75 6.75" />
	</motion.svg>
);

export const NodeLabel = React.forwardRef<HTMLDivElement, INodeLabelProps>(
	({ name, type, to, open = false, insetFactor = 0, ...rest }, ref) => {
		const isRoot = name === 'Library';
		const paddingY = isRoot ? theme.space.space12 : theme.space.space4;

		const { pathname } = useLocation();
		const isActive = pathname === to;

		return (
			<div
				className={nodeLabelStyles.wrapper}
				style={{
					paddingLeft: `${insetFactor * 1.5}rem`,
					cursor: type === 'node' ? 'pointer' : 'default',
					color: isActive ? theme.color.primary900 : undefined,
					background: isActive ? theme.color.primary50 : undefined
				}}
				ref={ref}
				{...rest}
			>
				{type === 'node' && (
					<div className={nodeLabelStyles.icon}>
						<NodeIndicator open={open} />
					</div>
				)}
				<NavLink to={to} className={nodeLabelStyles.label}>
					<Icon icon={name === 'Library' ? 'BookOpen' : 'Folder'} />
					<div
						className={nodeLabelStyles.name}
						style={{
							paddingTop: paddingY,
							paddingBottom: paddingY
						}}
					>
						{name}
					</div>
				</NavLink>
			</div>
		);
	}
);
