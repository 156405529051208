import { cx } from '@emotion/css';
import { Badge, FlexBox, Text } from 'cymantic-ui';
import { convertScoreToPercentage, getBadgeColor } from 'routes/SearchPage/list-view/helper';
import { listView } from 'routes/SearchPage/list-view/list-view.styles';

type ListItemProps = {
	onClick?: () => void;
	title: string;
	count: number;
	relativityScore: number;
	isSelected: boolean;
};

export const ListItem = ({ title, count, onClick, isSelected, relativityScore }: ListItemProps) => {
	const { badgeColor, textColor } = getBadgeColor(relativityScore);

	return (
		<li className={cx(listView.container, isSelected && listView.selected)}>
			<FlexBox direction="row" gap="space8">
				<Text>{title}</Text>
				<Badge size="sm" backgroundColor={badgeColor} color={textColor}>
					{convertScoreToPercentage(relativityScore)} Relativity
				</Badge>
			</FlexBox>
			<Text color="grey600" variant="bodyXXS">
				{count} results
			</Text>
			<button
				aria-label={`Select ${title}`}
				className={listView.selectButton}
				onClick={onClick}
				type="button"
			/>
		</li>
	);
};
