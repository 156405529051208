import { checkIsUnauthorized, Unauthorized } from 'components/Auth/Unauthorized';
import {
	Button,
	CenterContent,
	DateFormat,
	Icon,
	InlineAlert,
	Link,
	Text
} from 'cymantic-ui/dist/atomic-components';
import { useDisclosureControl } from 'cymantic-ui/dist/hooks';
import { theme } from 'cymantic-ui/dist/theme';
import * as React from 'react';
import { BeatLoader } from 'react-spinners';
import { DocumentModel } from 'redux/features/cluster';
import { ClusterQuery } from 'redux/features/search';
import { useLazyGetDocumentSummaryQuery } from 'redux/features/summary';
import { docRow } from 'routes/SearchPage/doc-panel/doc-panel.styles';
import { useDocPanel } from 'routes/SearchPage/doc-panel/doc-panel-provider';
import { DocModal } from 'routes/SearchPage/doc-panel/doc-pdf-modal';

type DocRowProps = {
	searchInfo: ClusterQuery;
	lazyLoadRowIndex: number;
	lazyLoadStyle: React.CSSProperties;
	setLazyLoadRowHeight: (index: number, height: number) => void;
	data: DocumentModel;
};

export const DocRow = ({
	searchInfo,
	lazyLoadRowIndex,
	lazyLoadStyle,
	setLazyLoadRowHeight,
	data
}: DocRowProps) => {
	const { summaryType } = useDocPanel();
	const { isOpen, handleClose, handleOpen } = useDisclosureControl();
	const lazyLoadRowRef = React.useRef<HTMLDivElement>(null);
	const [
		getDocumentSummary,
		{
			data: summaryData,
			error: summaryError,
			isLoading: isSummaryLoading,
			isError: isSummaryError
		}
	] = useLazyGetDocumentSummaryQuery();
	const { index: databaseIndex, lens } = searchInfo;
	/**
	 * TODO: Remove once an API route is available to determine which DBs support abstractive summaries
	 */
	const isPubmed = databaseIndex === 'pubmed';
	const summaryMethod = isPubmed ? summaryType : 'extractive';
	const hasData = !!summaryData;
	const isOpenByDefault = databaseIndex === 'shannon';
	const { mainTitle, subTitle, date, uuid, secondSubTitle, preview } = data;
	const isModalAvailable = !!preview && !!preview.index && !!uuid;

	const onExpand = () => {
		if (!hasData) {
			getDocumentSummary({
				documentId: uuid,
				index: databaseIndex,
				lens,
				type: summaryMethod
			});
		}
	};

	const {
		isOpen: isExpanded,
		handleOpen: handleExpand,
		handleToggle
	} = useDisclosureControl({ defaultIsOpen: isOpenByDefault, onOpen: onExpand });

	React.useEffect(() => {
		if (isOpenByDefault && !hasData) {
			handleExpand();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (lazyLoadRowRef.current) {
			setLazyLoadRowHeight(
				lazyLoadRowIndex,
				lazyLoadRowRef.current.getBoundingClientRect().height
			);
		}
	}, [
		isExpanded,
		setLazyLoadRowHeight,
		lazyLoadRowIndex,
		lazyLoadRowRef,
		hasData,
		isSummaryError
	]);

	if (checkIsUnauthorized(summaryError)) {
		return <Unauthorized />;
	}

	return (
		<>
			<div style={lazyLoadStyle}>
				<div ref={lazyLoadRowRef} className={docRow.root}>
					<div className={docRow.info}>
						{mainTitle?.text && mainTitle?.externalLink ? (
							<Link
								label={mainTitle?.text}
								href={mainTitle?.externalLink}
								isBlank
								iconRight="ExternalLink"
							/>
						) : (
							<Text variant="bodySM" color="grey900">
								{mainTitle?.text}
							</Text>
						)}

						{subTitle?.externalLink ? (
							<Link
								variant="bodyXS"
								label={
									subTitle?.key && subTitle.text
										? `${subTitle?.key}: ${subTitle?.text}`
										: subTitle?.text
								}
								href={subTitle?.externalLink}
								isBlank
								iconRight="ExternalLink"
							/>
						) : (
							<Text variant="bodyXS" tag="div">
								{subTitle?.key && subTitle.text
									? `${subTitle?.key}: ${subTitle?.text}`
									: subTitle?.text}
							</Text>
						)}

						{date && (
							<Text variant="bodyXXS" color="grey600" tag="div">
								<DateFormat variant={date.displayType} timestamp={date.datetime} />
							</Text>
						)}
						{!date && secondSubTitle && (
							<Text variant="bodyXXS" color="grey600" tag="div">
								{secondSubTitle?.key && secondSubTitle.text
									? `${secondSubTitle?.key}: ${secondSubTitle?.text}`
									: secondSubTitle?.text}
							</Text>
						)}

						{isExpanded && summaryData && (
							<p>
								<Text tag="span" variant="bodyXXS">
									<Text tag="span" variant="bodyXXS">
										{summaryData.summary.includes('Content:')
											? summaryData.summary.replace('Content:', '')
											: summaryData.summary}
									</Text>
								</Text>
							</p>
						)}

						{isExpanded && isSummaryError && (
							<p>
								<CenterContent>
									<InlineAlert variant="error" size="xs">
										An error occurred while generating this summary. If the
										issue persists, please contact us at{' '}
										<Link
											label="support@cymantix.com"
											href="mailto:support@cymantix.com"
										/>
										.
									</InlineAlert>
								</CenterContent>
							</p>
						)}
						{isModalAvailable && (
							<div>
								<Button
									onClick={handleOpen}
									label="View PDF"
									iconLeft="FileText"
									variant="grey"
									subVariant="outline"
									size="xs"
								/>
							</div>
						)}
					</div>
					<button onClick={handleToggle} aria-label="Toggle Document Summary">
						{isSummaryLoading ? (
							<BeatLoader size={6} color={theme.color.primary400} />
						) : (
							<Icon icon={isExpanded ? 'ChevronUp' : 'ChevronDown'} size="space20" />
						)}
					</button>
				</div>
			</div>
			{isOpen && (
				<DocModal
					index={preview?.index ?? ''}
					uuid={uuid}
					isOpen={isOpen}
					onClose={handleClose}
				/>
			)}
		</>
	);
};
