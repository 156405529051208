import { Button } from 'cymantic-ui/dist/atomic-components/button';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { ClusterSVGSprites } from 'routes/SearchPage/ClusterView/ClusterSVGSprites';
import { clusterView } from 'routes/SearchPage/ClusterView/ClusterView.styles';
import { renderClusterView } from 'routes/SearchPage/ClusterView/ClusterViewD3Renderers';
import { resetClusterView } from 'routes/SearchPage/ClusterView/ClusterViewHelpers';

const ClusterView = () => {
	const results = useAppSelector((s) => s.cluster.results);
	const searchInfo = useAppSelector((s) => s.search.clusterQuery);
	const search = useAppSelector((s) => s.search);
	const clusterVis = useAppSelector((s) => s.cluster);

	const { activeDatabase } = search;
	const { data } = clusterVis;

	const index = searchInfo?.index ?? '';
	const query = searchInfo?.query ?? '';

	const svgRef = React.useRef<HTMLDivElement>(null);
	const navigate = useNavigate();

	const hasClustersAndDocuments =
		Object.keys(results?.groups ?? {}).length > 0 && (results?.totalDocCount ?? 0) > 0;

	const isShannonFile =
		index === 'shannon' &&
		typeof activeDatabase === 'string' &&
		activeDatabase.includes('file-');
	const linkToShannonFile = isShannonFile
		? `/library/file/transcript/${activeDatabase.replace('file-', '')}`
		: '';

	const handleGoToShannonFile = () => {
		navigate(linkToShannonFile);
	};

	// USES DATA and resets & renders ClusterView
	React.useEffect(() => {
		const hasData = data.length > 0;

		if (hasData && hasClustersAndDocuments) {
			resetClusterView(svgRef);
			renderClusterView(data, svgRef, query);
		}
	}, [data, hasClustersAndDocuments, query, results]);

	return (
		<div className={clusterView.root}>
			<ClusterSVGSprites />
			{hasClustersAndDocuments && (
				<>
					<div
						className={`${clusterView.clusterVisualization} cluster-visualization`}
						ref={svgRef}
					/>
					{isShannonFile && (
						<div className={clusterView.transcriptLink}>
							<Button
								size="sm"
								variant="grey"
								subVariant="outline"
								label="Go to full transcript"
								onClick={handleGoToShannonFile}
								iconRight="ArrowRight"
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default ClusterView;
