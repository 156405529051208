import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const pageHeader = {
	root: css`
		align-items: center;
		background-color: ${theme.color.white};
		border-bottom: 1px solid ${theme.color.grey200};
		display: flex;
		flex-wrap: wrap;
		gap: ${theme.space.space16};
		justify-content: space-between;
		padding: ${theme.space.space24} ${theme.space.space32};
	`,
	right: css`
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		gap: ${theme.space.space16};
	`,
	left: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space8};
	`,
	title: css`
		align-items: center;
		display: flex;
		gap: ${theme.space.space8};
	`
};
