import {
	ClusterDataItem,
	Groups,
	Keywords,
	MemberDoc
} from 'redux/features/cluster/cluster.definitions';

type MappedData = {
	key: string;
	sizeValue: number;
	keywords: Keywords;
	memberDocs: MemberDoc[];
	summary?: string | undefined;
}[];

/**
 * calcClusterRelativityColor
 *
 * Calculates the "relativity" of each cluster, which is a measure of how
 * representative a cluster is of the larger dataset. This is used to color
 * the clusters in the visualization.
 *
 * @param {Array} clusters - Array of cluster data.
 * @returns {Array} - Array of cluster data with added "score" property.
 */
function calcClusterRelativityColor(clusters: MappedData, query: string) {
	// If query is empty, return data with 100% relativity for each cluster
	if (!query) {
		return clusters.map((item) => ({
			...item,
			score: 100,
			zScore: 1
		}));
	}

	// Store all z-scores to calculate min/max
	const runningScores: number[] = [];

	// Calculate standard deviation and mean of member document scores for all clusters
	const scores: number[] = clusters.flatMap((cluster) =>
		cluster.memberDocs.map((doc) => doc.score)
	);
	const mean: number = scores.reduce((acc, score) => acc + score, 0) / scores.length;
	const std: number = Math.sqrt(
		scores.reduce((acc, score) => acc + (score - mean) ** 2, 0) / scores.length
	);

	// Calculate z-score for each cluster based on the average score of its member documents
	const clustersWithZScores = clusters.map((cluster) => {
		const totalScore = cluster.memberDocs.reduce((acc, doc) => acc + doc.score, 0);
		const avgScore = totalScore / cluster.memberDocs.length;
		const zScore = (avgScore - mean) / std;

		runningScores.push(zScore);
		return { ...cluster, zScore };
	});

	// Calculate "score" for each cluster based on z-scores, and store in "score" property
	const minZScore = Math.min(...runningScores);
	const maxZScore = Math.max(...runningScores);

	const clustersWithScores = clustersWithZScores.map((cluster) => {
		const score = ((cluster.zScore - minZScore) / (maxZScore - minZScore)) * 99 + 1;
		return { ...cluster, score };
	});

	return clustersWithScores;
}

export function formatD3ClusterData(data: Groups, query: string) {
	const mappedData = Object.entries(data).map(([key, values]) => {
		return {
			...values,
			key,
			sizeValue: values.memberDocs.length
		};
	});

	// Calculate relativity scores and add colorValue property
	const dataWithScores = calcClusterRelativityColor(mappedData, query)
		.map((item) => ({
			...item,
			colorValue: item.score
		}))
		.sort((a, b) => b.colorValue - a.colorValue);

	return dataWithScores as ClusterDataItem[];
}

export function getAllMemberDocsInBasket(data: ClusterDataItem[], basket: number[]) {
	const filteredData = data.filter((obj) => {
		const nodeKey = obj.key;
		// eslint-disable-next-line radix
		return basket.includes(parseInt(nodeKey));
	});

	const allMemberDocs: MemberDoc[] = [];

	filteredData.forEach((item) => {
		item.memberDocs.forEach((member) => {
			if (!allMemberDocs.includes(member)) {
				allMemberDocs.push(member);
			}
		});
	});

	return allMemberDocs;
}
