import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';
import { tabPanelHeight } from 'routes/helper';

export const searchPage = {
	root: css`
		background-color: ${theme.color.grey50};
		display: grid;
		grid-template-columns: auto;
		height: 100%;
		width: 100%;
	`,
	tabRoot: css`
		background: var(--cy-color-grey100);
		background-image: radial-gradient(#e5e5e5 20%, transparent 20%);
		background-repeat: repeat;
		background-size: 0.625rem 0.625rem;
		display: flex;
		height: 100%;
		height: ${tabPanelHeight};
		width: 100%;
	`,
	content: css`
		display: flex;
		height: 100%;
		position: relative;
		width: 100%;
	`,
	contentInner: css`
		height: 100%;
		position: relative;
		width: 100%;
	`,
	relativityPanel: css`
		position: absolute;
		right: ${theme.space.space24};
		top: ${theme.space.space16};
	`,
	bottomControls: css`
		bottom: ${theme.space.space24};
		display: flex;
		gap: ${theme.space.space8};
		position: absolute;
		right: ${theme.space.space24};
	`,
	tableViewPanel: css`
		background-color: ${theme.color.grey50};
		display: flex;
		flex-direction: row;
		height: ${tabPanelHeight};
	`
};
