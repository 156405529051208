import { loaderWrapper } from 'components/Auth/AuthLoader.styles';
import { CenterContent } from 'cymantic-ui/dist/atomic-components/center-content';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import { theme } from 'cymantic-ui/dist/theme';
import * as React from 'react';
import { BeatLoader } from 'react-spinners';

export const AuthLoader = ({ children }: { children: React.ReactNode }) => (
	<CenterContent>
		<div className={loaderWrapper}>
			<Text fontStyle="italic" color="grey500">
				{children}
			</Text>
			<BeatLoader size={15} color={theme.color.primary900} loading />
		</div>
	</CenterContent>
);
