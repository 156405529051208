import { createSlice } from '@reduxjs/toolkit';
import { ToastProps } from 'cymantic-ui/dist/atomic-components/toast';

export type ToastType = ToastProps & {
	id: string;
	timeout?: number;
};

const initialState: ToastType[] = [];

export const toastSlice = createSlice({
	name: 'toast',
	initialState,
	reducers: {
		addToast: (state, action) => {
			const toast = action.payload;
			if (
				state.some(
					(existingToast) =>
						existingToast.content === toast.content &&
						existingToast.title === toast.title &&
						existingToast.variant === toast.variant
				)
			) {
				return state;
			}
			const returnState = [...state, toast];
			return returnState;
		},
		removeToast: (state, action) => {
			const id = action.payload;
			const returnState = [...state.filter((toast) => toast.id !== id)];
			return returnState;
		}
	}
});

export const { addToast, removeToast } = toastSlice.actions;
export const toastReducer = toastSlice.reducer;
