import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const dashboardPageCard = {
	root: css`
		background: ${theme.color.white};
		border: 1px solid ${theme.color.grey300};
		border-radius: ${theme.borderRadius.radiusMD};
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	`,
	body: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space32};
		padding: 0 ${theme.space.space24} ${theme.space.space24} ${theme.space.space24};
	`,
	header: css`
		padding: ${theme.space.space24};
	`
};
