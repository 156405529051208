import { useCallback, useEffect, useRef } from 'react';
import { addToast, removeToast, ToastType } from 'redux/features/toast/toast-slice';
import { useAppDispatch } from 'redux/hooks';
import { v4 as uuid } from 'uuid';

export const useToast = () => {
	const dispatch = useAppDispatch();
	const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

	const add = useCallback(
		({ timeout = 3000, ...rest }: Omit<ToastType, 'id'>) => {
			const id = uuid();
			dispatch(addToast({ id, ...rest }));

			if (timeout) {
				timeoutIdRef.current = setTimeout(() => {
					dispatch(removeToast(id));
				}, timeout);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		/* clear the timeout to prevent Memory Leaks with setTimeout */
		return () => {
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
		};
	}, []);

	return {
		addToast: add
	};
};
