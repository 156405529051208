import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from 'components/CenteredLayout/centered-layout';
import { FolderLink } from 'components/FolderLink/FolderLink';
import { PageSection } from 'components/PageSection/PageSection';
import { TableNoData, TableSkeleton } from 'components/Table/helpers';
import { CenterContent } from 'cymantic-ui/dist/atomic-components/center-content';
import { RouterLink } from 'cymantic-ui/dist/atomic-components/link';
import { SkeletonAreaLoader } from 'cymantic-ui/dist/atomic-components/skeleton-loader';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import { Folder } from 'redux/features/library';
import { LibraryFolderRow, LibraryLayoutContent } from 'routes/Library/LibraryLayout';
import { LibraryTable } from 'routes/Library/LibraryTable/LibraryTable';

export const LibraryFolderSkeleton = () => (
	<LibraryLayoutContent>
		<PageSection title=" " isLoading>
			<LibraryFolderRow>
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
			</LibraryFolderRow>
		</PageSection>
		<PageSection title=" " isLoading>
			<TableSkeleton />
		</PageSection>
	</LibraryLayoutContent>
);

export const LibraryFolderEmpty = () => (
	<CenterContent isFullHeight>
		<CenteredLayoutContainer>
			<CenteredLayoutSubContainer>
				<Text tag="h1" variant="headerH4" textAlign="center">
					You don’t have any Shannon meeting transcripts yet.
				</Text>
				<Text tag="p" variant="bodySM" color="grey700" textAlign="center">
					When meeting transcripts are saved they will appear here.
				</Text>
			</CenteredLayoutSubContainer>
			<CenteredLayoutSubContainer>
				<RouterLink to="/library" iconLeft="ArrowLeft" label="Return to Library" />
			</CenteredLayoutSubContainer>
		</CenteredLayoutContainer>
	</CenterContent>
);

type LibraryFolderContentProps = {
	data: Folder;
	folderId: string;
};

export const LibraryFolderContent = ({ data, folderId }: LibraryFolderContentProps) => {
	const hasSubFolders = !!data && data.items.length > 0;
	const hasFiles = !!data && data?.file_count >= 1;

	return (
		<LibraryLayoutContent>
			{hasSubFolders && (
				<PageSection title="Folders">
					<LibraryFolderRow>
						{data.items.map((subfolder) => {
							return (
								<FolderLink
									key={subfolder.id}
									label={subfolder.name}
									to={`/library/folder/${subfolder.id}`}
								/>
							);
						})}
					</LibraryFolderRow>
				</PageSection>
			)}
			<PageSection title="Files">
				{hasFiles ? (
					<LibraryTable folderId={folderId || ''} fileCount={data.file_count} />
				) : (
					<TableNoData message="This folder is currently empty." />
				)}
			</PageSection>
		</LibraryLayoutContent>
	);
};
