import { FlexBox } from 'cymantic-ui/dist/atomic-components/flex-box';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import { theme } from 'cymantic-ui/dist/theme';
import {
	relativity,
	relativityPanel
} from 'routes/SearchPage/RelativityPanel/RelativityPanel.styles';
import { searchPage } from 'routes/SearchPage/SearchPage.styles';

type RelativityProps = {
	color: string;
	label: string;
	border: string;
};

const Relativity = ({ color, label, border }: RelativityProps) => (
	<div className={relativity.root}>
		<div
			className={relativity.colorSwatch}
			style={{ background: `${color}`, border: `${border}` }}
		/>
		<Text tag="span" variant="bodyTiny">
			{label}
		</Text>
	</div>
);

export const RelativityPanel = () => (
	// TODO: Accessibility: format as list, figure out correct heading level
	<div className={searchPage.relativityPanel}>
		<section className={relativityPanel.root} aria-label="Color Key for Cluster View">
			<FlexBox gap="space4">
				<Text tag="h3" variant="bodyTiny" isBold>
					Relevance to Query
				</Text>
				<Relativity
					label="76-100%"
					color="linear-gradient(313.54deg, #3E5CE0 0%, #5A79E8 137.09%)"
					border="none"
				/>
				<Relativity
					label="51-75%"
					color="linear-gradient(314.43deg, #6584EB 0%, #839DF0 77.98%, #839DF0 124.76%)"
					border="none"
				/>
				<Relativity
					label="26-50%"
					color="linear-gradient(317.98deg, #B6C6F6 31.71%, #D3DDFA 132.21%)"
					border="none"
				/>
				<Relativity
					label="< 25%"
					color={theme.color.grey50}
					border={`1px solid ${theme.color.grey200}`}
				/>
			</FlexBox>

			<FlexBox>
				<Text tag="h3" variant="bodyTiny" isBold>
					Size
				</Text>
				<Text tag="div" variant="bodyTiny" color="grey700">
					Circles size represents the number of results in the cluster.
				</Text>
			</FlexBox>
		</section>
	</div>
);
