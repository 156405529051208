import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClusterState, ClusterStateUpdate } from 'redux/features/cluster/cluster.definitions';
import {
	formatD3ClusterData,
	getAllMemberDocsInBasket
} from 'redux/features/cluster/cluster-helpers';

const initialState: ClusterState = {
	basket: [],
	data: [],
	cachedVis: {},
	selectedMemberDocs: [],
	totalDocCount: undefined,
	results: undefined
};

export const clusterSlice = createSlice({
	name: 'cluster',
	initialState,
	reducers: {
		addNodeToBasket: (state, action: PayloadAction<number>) => {
			const nodeId = action.payload;
			const basket = [...state.basket, nodeId];
			const selectedMemberDocs = getAllMemberDocsInBasket(state.data, basket);

			return { ...state, basket, selectedMemberDocs };
		},
		removeNodeFromBasket: (state, action: PayloadAction<number>) => {
			const nodeId = action.payload;
			const basket = [...state.basket.filter((item) => item !== nodeId)];
			const selectedMemberDocs = getAllMemberDocsInBasket(state.data, basket);

			return { ...state, basket, selectedMemberDocs };
		},
		startSearch: () => ({ ...initialState }),
		startZoomIn: (state, action: PayloadAction<number>) => ({
			...state,
			cachedVis: { ...state.cachedVis, [action.payload]: state.selectedMemberDocs },
			selectedMemberDocs: [],
			totalDocCount: undefined
		}),
		startZoomOut: (state) => ({
			...state,
			selectedMemberDocs: [],
			totalDocCount: undefined
		}),
		startZoomOutFromBasket: (state, action: PayloadAction<number>) => ({
			...state,
			cachedVis: { ...state.cachedVis, [action.payload]: state.selectedMemberDocs },
			selectedMemberDocs: [],
			totalDocCount: undefined
		}),

		setSearchResults: (state, action: PayloadAction<ClusterStateUpdate>) => {
			const results = action.payload;
			const { code, totalDocCount, groups } = results;
			const data = (groups && formatD3ClusterData(groups, results.query ?? '')) ?? [];

			if (code === 500) {
				return {
					...initialState,
					results: {
						code,
						userMessage: results.userMessage,
						groups: [],
						similarityMatrix: [],
						totalDocCount: 0,
						docIds: []
					}
				};
			}

			if (code === 200) {
				return {
					...state,
					basket: [],
					cachedVis: {},
					results,
					data,
					totalDocCount
				};
			}

			return { ...state };
		},
		setZoomInResults: (state, action: PayloadAction<ClusterStateUpdate>) => {
			const results = action.payload;
			const { code, totalDocCount, groups } = results;

			if (code === 200) {
				const data = formatD3ClusterData(groups, results.query ?? '') ?? [];

				return {
					...state,
					basket: [],
					data,
					results,
					totalDocCount
				};
			}

			if (code === 500) {
				return {
					...state,
					results
				};
			}

			return { ...state };
		},

		setZoomOutResults: (state, action: PayloadAction<ClusterStateUpdate>) => {
			const results = action.payload;
			const { code, totalDocCount, groups } = results;

			if (code === 200) {
				const data = formatD3ClusterData(groups, results.query ?? '') ?? [];

				return {
					...state,
					basket: [],
					cachedVis: {},
					data,
					results,
					totalDocCount
				};
			}

			if (code === 500) {
				return {
					...state,
					results
				};
			}

			return { ...state };
		},
		setZoomOutFromBasketResults: (state, action: PayloadAction<ClusterStateUpdate>) => {
			const results = action.payload;
			const { code, totalDocCount, groups } = results;

			if (code === 200) {
				const data = formatD3ClusterData(groups, results.query ?? '') ?? [];

				return {
					...state,
					basket: [],
					data,
					results,
					selectedMemberDocs: [],
					totalDocCount
				};
			}

			if (code === 500) {
				return {
					...state,
					results
				};
			}

			return { ...state };
		}
	}
});

export const {
	addNodeToBasket,
	removeNodeFromBasket,
	setZoomOutFromBasketResults,
	setZoomOutResults,
	startSearch,
	startZoomIn,
	startZoomOut,
	startZoomOutFromBasket,
	setSearchResults,
	setZoomInResults
} = clusterSlice.actions;

export const clusterReducer = clusterSlice.reducer;
