import { css } from '@emotion/css';

export const meetingEndedCard = {
	overflowWrapper: css`
		overflow: hidden;
		position: relative;
	`,
	skeletonWrapper: css`
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 10;
	`,
	mainActionWrapper: css`
		position: relative;
	`,
	mainActionLink: css`
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	`
};
