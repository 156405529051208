import { DocsResponse, DocumentModel, MemberDoc, SortOptions } from 'redux/features/cluster';
import { SummaryType } from 'redux/features/summary';
import {
	getSummaryTypeInitialState,
	sortDocuments
} from 'routes/SearchPage/doc-panel/doc-panel-provider-helpers';

export type DocsState = {
	docs: DocumentModel[];
	filteredDocs: DocumentModel[];
	docIds: Set<string>;
	selectedSortOption: string;
	sortOptions?: SortOptions;
	summaryType: SummaryType;
};

export const initialDocsState: DocsState = {
	docs: [],
	filteredDocs: [],
	docIds: new Set(),
	selectedSortOption: '',
	summaryType: getSummaryTypeInitialState(),
	sortOptions: undefined
};

export type DocsAction =
	| { type: 'SET_DOCS'; payload: DocsResponse }
	| { type: 'SORT_DOCS'; payload: string }
	| { type: 'FILTER_DOCS'; payload: MemberDoc[] }
	| { type: 'SET_SUMMARY_TYPE'; payload: SummaryType };

export function docsReducer(state: DocsState, action: DocsAction) {
	switch (action.type) {
		case 'SET_DOCS': {
			const { sortOptions, docList } = action.payload;
			// Sort the documents based on the default sort option.
			const sortedDocs = sortDocuments(docList, sortOptions?.default ?? '');
			// Create a Set of document IDs for quick lookup.
			const docIds = new Set(sortedDocs.map((doc) => doc.uuid));

			return {
				...state,
				docs: sortedDocs,
				docIds,
				sortOptions,
				selectedSortOption: sortOptions?.default ?? ''
			};
		}
		case 'SORT_DOCS': {
			const sortOption = action.payload;
			const sortedDocs = sortDocuments(state.filteredDocs, action.payload);

			return { ...state, filteredDocs: sortedDocs, selectedSortOption: sortOption };
		}
		case 'FILTER_DOCS': {
			const selectedMemberDocs = action.payload;
			// Create a Set of UUIDs from the selected MemberDocs for efficient lookup.
			const selectedMemberDocsUuids = new Set(selectedMemberDocs.map((m) => m.uuid));
			const filteredDocs =
				selectedMemberDocs.length === 0
					? state.docs
					: state.docs.filter((doc) => selectedMemberDocsUuids.has(doc.uuid));
			return { ...state, filteredDocs };
		}
		case 'SET_SUMMARY_TYPE':
			return { ...state, summaryType: action.payload };
		default:
			return state;
	}
}
