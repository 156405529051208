import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

const host = process.env.REACT_APP_MEET_API_HOST;

const baseQuery = fetchBaseQuery({
	baseUrl: `${host}/`,
	prepareHeaders: async (headers) => {
		headers.set('Content-Type', 'application/json');

		const token = Cookies.get('idToken');
		headers.set('Authorization', `Bearer ${token}`);

		return headers;
	}
});

/**
 * This is the base api
 * @use TO ADD ENDPOINTS - features/myfeature/feature-queries.ts => apiBase.injectEndpoints
 */
export const meetApiBase = createApi({
	reducerPath: 'meet-api',
	baseQuery,
	endpoints: () => ({})
});
