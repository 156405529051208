import { checkIsUnauthorized, Unauthorized } from 'components/Auth/Unauthorized';
import { DetailPageHeader } from 'components/detail-page-header';
import { AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { ContentLink } from 'redux/features/cluster';
import { useGetFileQuery } from 'redux/features/library';
import { LibraryLayout } from 'routes/Library/LibraryLayout';
import { PageTransition } from 'routes/Library/LibraryTransition';
import {
	TranscriptFileContent,
	TranscriptFileError,
	TranscriptFileSkeleton
} from 'routes/Library/TranscriptFilePage/TranscriptFileStates';

type BadgeTitleKeys = (typeof ContentLink)[keyof typeof ContentLink] | 'shannon';

const badgeTitle: Record<BadgeTitleKeys, string> = {
	pdf: '.PDF',
	transcript: 'Transcript',
	/* TODO: remove when BE is ready */
	shannon: 'Transcript',
	web: 'Webpage',
	text: 'Text'
};

const TranscriptFilePage = () => {
	const { fileId, fileType } = useParams();

	const { data, isError, isLoading, isSuccess, error } = useGetFileQuery(fileId ?? '');

	const hasData = isSuccess && !!data;
	const hasHeader = isLoading || hasData;

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	return (
		<LibraryLayout>
			{hasHeader && (
				<DetailPageHeader
					badgeTitle={badgeTitle[fileType as BadgeTitleKeys]}
					date={data?.createdAt}
					title={data?.name ?? ' '}
					backToLabel={data?.parent.name ?? ' '}
					backTo={`/library/folder/${data?.parent.id}` ?? ' '}
					isLoading={isLoading}
				/>
			)}
			<AnimatePresence>
				{isLoading && (
					<PageTransition key="transcriptSkeleton" hasHeader={hasHeader}>
						<TranscriptFileSkeleton />
					</PageTransition>
				)}
				{isError && (
					<PageTransition delay={0.3} key="transcriptError" hasHeader={hasHeader}>
						<TranscriptFileError />
					</PageTransition>
				)}
				{hasData && (
					<PageTransition delay={0.3} key="transcriptContent" hasHeader={hasHeader}>
						<TranscriptFileContent data={data.content} />
					</PageTransition>
				)}
			</AnimatePresence>
		</LibraryLayout>
	);
};

export default TranscriptFilePage;
