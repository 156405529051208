import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const dashboardTable = css`
	border-collapse: separate;
	border-spacing: 0;
	width: 100%;

	td,
	th {
		padding: ${theme.space.space8};
	}

	th {
		border-top-style: solid;
		color: ${theme.color.grey700};
		font-size: ${theme.fontSize.bodyXXS};
		text-align: left;
	}

	td {
		background-color: ${theme.color.white};
		border-top: 1px solid ${theme.color.grey300};
		color: ${theme.color.grey800};
		font-size: ${theme.fontSize.bodyXS};
	}

	tr:hover td {
		background: ${theme.color.grey100};
	}
`;
