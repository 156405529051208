import {
	libraryFolderRow,
	libraryLayout,
	libraryLayoutContent
} from 'routes/Library/LibraryLayout.styles';

type LibraryLayoutProps = {
	children?: React.ReactNode;
};

export const LibraryLayout = ({ children }: LibraryLayoutProps) => (
	<div className={libraryLayout}>{children}</div>
);

type LibraryLayoutContentProps = {
	children?: React.ReactNode;
};

export const LibraryLayoutContent = ({ children }: LibraryLayoutContentProps) => (
	<main className={libraryLayoutContent}>{children}</main>
);

type LibraryFoldersRow = {
	children?: React.ReactNode;
};

export const LibraryFolderRow = ({ children }: LibraryFoldersRow) => (
	<div className={libraryFolderRow}>{children}</div>
);
