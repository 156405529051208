import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Button } from 'cymantic-ui/dist/atomic-components/button';
import { useNavigate } from 'react-router-dom';
import { TranscriptFilesResponse } from 'redux/features/library';
import { dashboardTable } from 'routes/DashboardPage/RecentFilesCard/RecentFilesTable.styles';
import { tableColumns } from 'routes/Library/LibraryTable/LibraryTable.definitions';

type RecentFilesTableProps = {
	data: TranscriptFilesResponse[];
};

export const RecentFilesTable = ({ data }: RecentFilesTableProps) => {
	const table = useReactTable({
		columns: tableColumns,
		data,
		getCoreRowModel: getCoreRowModel()
	});

	const navigate = useNavigate();
	const handleLibraryNavigation = () => navigate('/library');

	return (
		<>
			<table className={dashboardTable}>
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<th key={header.id}>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext()
										  )}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => (
						<tr key={row.id}>
							{row.getVisibleCells().map((cell) => (
								<td key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			<Button
				variant="grey"
				subVariant="outline"
				label="Go to Library"
				onClick={handleLibraryNavigation}
				iconRight="ArrowRight"
			/>
		</>
	);
};
