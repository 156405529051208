import {
	Button,
	FlexBox,
	FullScreenModal,
	FullScreenModalBody,
	FullScreenModalFooter,
	SkeletonAreaLoader
} from 'cymantic-ui/dist/atomic-components';
import { createPortal } from 'react-dom';
import { DocPreviewRequest, useGetDocPreviewQuery } from 'redux/features/cluster';
import { Error } from 'routes/SearchPage/ClusterView/ClusterStates';

/* TODO: Move the portal to the UI repo */
type ModalPortalProps = {
	children?: React.ReactNode;
};

const ModalPortal = ({ children }: ModalPortalProps) => {
	const container = document.querySelector('body');
	if (!container) {
		return null;
	}

	return createPortal(children, container);
};

type DocModalProps = DocPreviewRequest & {
	onClose: () => void;
	isOpen: boolean;
};

export const DocModal = ({ onClose, isOpen, index, uuid }: DocModalProps) => {
	const { data, error, isLoading } = useGetDocPreviewQuery({ index, uuid });
	const hasError = !isLoading && !!error;

	return (
		<ModalPortal>
			<FullScreenModal isOpen={isOpen} onClose={onClose} title={data?.title ?? ''}>
				<FullScreenModalBody>
					<FlexBox height="100%">
						{isLoading && <SkeletonAreaLoader width="100%" height="100%" />}
						{hasError ? (
							<Error />
						) : (
							<iframe
								src={data?.url}
								title={data?.title ?? ''}
								width="100%"
								height="100%"
							/>
						)}
					</FlexBox>
				</FullScreenModalBody>
				<FullScreenModalFooter>
					<FlexBox justifyContent="space-between" direction="row">
						<Button
							label="Close"
							onClick={onClose}
							variant="grey"
							subVariant="outline"
						/>
					</FlexBox>
				</FullScreenModalFooter>
			</FullScreenModal>
		</ModalPortal>
	);
};
