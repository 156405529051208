import {
	FileResponse,
	Folder,
	TranscriptFilesRequest,
	TranscriptFilesResponse
} from 'redux/features/library/library.definitions';
import { ruthiApiBase } from 'redux/ruthi-api-base';

export const libraryQueries = ruthiApiBase.injectEndpoints({
	endpoints: (builder) => ({
		getFiles: builder.query<TranscriptFilesResponse[], TranscriptFilesRequest>({
			query: (filesInput) => {
				const folderIdQuery = filesInput?.folderId ? `folderId=${filesInput.folderId}` : '';
				const pageQuery = filesInput?.page ? `page=${filesInput.page}` : '';
				const query = [folderIdQuery, pageQuery].filter((item) => !!item).join('&');
				const url = `getFiles/v2${query ? `?${query}` : ''}`;
				return url;
			}
		}),
		getFile: builder.query<FileResponse, string>({
			query: (fileId) => `v2/getFile/${fileId}`
		}),
		getFolders: builder.query<Folder[], null>({
			query: () => 'getAllFolders'
		}),
		getFolder: builder.query<Folder, string>({
			query: (folderId) => {
				return folderId.length > 0 ? `v2/getFolderInfo/${folderId}` : 'v2/getFolderInfo';
			}
		})
	})
});

export const {
	useGetFileQuery,
	useGetFilesQuery,
	useGetFolderQuery,
	useGetFoldersQuery,
	useLazyGetFileQuery,
	useLazyGetFilesQuery,
	useLazyGetFolderQuery,
	useLazyGetFoldersQuery
} = libraryQueries;
