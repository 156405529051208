import { pageHeader } from 'components/PageHeader/PageHeader.styles';
import {
	Icon,
	IconName,
	RouterLink,
	SkeletonAreaLoader,
	SkeletonTextLoader,
	Text
} from 'cymantic-ui/dist/atomic-components';
import * as React from 'react';

type PageHeaderProps = {
	children: React.ReactNode;
};

export const PageHeader = ({ children }: PageHeaderProps) => (
	<div className={pageHeader.root}>{children}</div>
);

type PageHeaderLeftProps = {
	backToLabel?: string;
	backTo?: string;
	icon?: IconName;
	title: string;
};

export const PageHeaderLeft = ({ title, icon, backTo, backToLabel }: PageHeaderLeftProps) => (
	<header className={pageHeader.left}>
		{backTo && backToLabel && (
			<RouterLink variant="bodyXXS" to={backTo} label={backToLabel} iconLeft="CornerLeftUp" />
		)}
		<div className={pageHeader.title}>
			{icon && <Icon icon={icon} size="space20" color="grey500" />}
			<Text variant="bodyM" color="grey600" tag="h1">
				{title}
			</Text>
		</div>
	</header>
);

type PageHeaderLeftSkeletonProps = {
	hasBackLink?: boolean;
	hasTitleIcon?: boolean;
};

export const PageHeaderLeftSkeleton = ({
	hasBackLink,
	hasTitleIcon
}: PageHeaderLeftSkeletonProps) => (
	<header className={pageHeader.left}>
		{hasBackLink && <SkeletonTextLoader variant="bodyXXS" width="100px" />}
		<div className={pageHeader.title}>
			{hasTitleIcon && <SkeletonAreaLoader variant="circular" width="20px" />}
			<SkeletonTextLoader variant="headerH4" width="200px" />
		</div>
	</header>
);

export const PageHeaderRight = ({ children }: { children: React.ReactNode }) => {
	return (
		<section aria-label="Page Actions" className={pageHeader.right}>
			{children}
		</section>
	);
};
