import { ToastProps } from 'cymantic-ui/dist/atomic-components/toast';

export const toastContents: Record<string, ToastProps> = {
	addedToLibrarySuccess: {
		content: 'Transcript added to library!',
		variant: 'success'
	},
	addedToLibraryError: {
		content:
			'An error occurred while adding to your library. Please try again, or download your transcript.',
		variant: 'error'
	},
	downloadSuccess: {
		content: 'Transcript downloaded successfully!',
		variant: 'success'
	},
	downloadError: {
		content: 'An error occurred downloading your transcript.',
		variant: 'error'
	}
};
