import { PageError } from 'components/PageError/PageError';
import { SkeletonTextLoader, Text } from 'cymantic-ui/dist/atomic-components';
import * as React from 'react';
import { FileContent } from 'redux/features/library';
import { transcriptFilePage } from 'routes/Library/TranscriptFilePage/TranscriptFilePage.styles';
import {
	TranscriptRow,
	TranscriptRowSkeleton
} from 'routes/Library/TranscriptFilePage/TranscriptRow';
import { getDurationFromUnix } from 'utilities/time-formatting';

export const TranscriptFileError = () => (
	<PageError
		title="An error occurred while retrieving your file"
		subtitle="Please try again later."
		alertVariant="error"
	/>
);

export const TranscriptFileSkeleton = () => (
	<>
		<div className={transcriptFilePage.info}>
			<SkeletonTextLoader variant="bodyXS" width="200px" />
			<SkeletonTextLoader variant="bodyXS" width="300px" />
		</div>
		<div className={transcriptFilePage.transcript}>
			{[...Array(10).keys()].map((i) => (
				<TranscriptRowSkeleton key={i} />
			))}
		</div>
	</>
);

type TranscriptFileContentProps = {
	data: FileContent;
};

export const TranscriptFileContent = ({ data }: TranscriptFileContentProps) => {
	const participants = data.participants.join(', ');
	const hasTranscripts = data.transcript.length > 0;

	const [meetingDurationHours, setMeetingDurationHours] = React.useState(0);

	const firstTimestamp = Number(data?.transcript[0].timestamp);
	const lastTimestamp = Number(data?.transcript[data.transcript.length - 1].timestamp);

	React.useEffect(() => {
		if (firstTimestamp && lastTimestamp) {
			const { hours } = getDurationFromUnix(firstTimestamp, lastTimestamp);

			if (hours && hours > 0) {
				setMeetingDurationHours(hours);
			}
		}
	}, [hasTranscripts, firstTimestamp, lastTimestamp]);

	return (
		<>
			<div className={transcriptFilePage.info}>
				<Text color="grey900" variant="bodyXS">
					{participants}
				</Text>
			</div>
			<ul className={transcriptFilePage.transcript}>
				{firstTimestamp &&
					data.transcript.map((item) => {
						const { text, speakerName, timestamp } = item;
						return (
							<TranscriptRow
								key={`${item.speakerName}${item.timestamp}`}
								firstTimestamp={firstTimestamp}
								hasHours={meetingDurationHours > 0}
								speakerName={speakerName}
								text={text}
								timestamp={Number(timestamp)}
							/>
						);
					})}
			</ul>
		</>
	);
};
