import { cx } from '@emotion/css';
import { FolderLinkVariant } from 'components/FolderLink/FolderLink.definitions';
import { folderLink } from 'components/FolderLink/FolderLink.styles';
import { Icon, IconName, Text } from 'cymantic-ui';
import { Link, LinkProps } from 'react-router-dom';

type FolderLinkProps = {
	label: string;
	iconName?: IconName;
	variant?: FolderLinkVariant;
} & LinkProps;

export const FolderLink = ({
	label,
	iconName = 'Folder',
	variant = 'primary',
	...linkProps
}: FolderLinkProps) => {
	return (
		<Link className={folderLink.root} {...linkProps}>
			<div className={cx(folderLink.icon, folderLink.iconVariant[variant])}>
				<Icon icon={iconName} size="space12" />
			</div>
			<Text variant="bodyXXS" color="grey900">
				{label}
			</Text>
		</Link>
	);
};
