import { DashboardPageCard } from 'components/DashboardPage/DashboardPageCard';
import { Button } from 'cymantic-ui/dist/atomic-components/button';
import * as React from 'react';
import { startMeetingCard } from 'routes/DashboardPage/StartMeetingCard/StartMeetingCard.styles';
import { getRoomLink } from 'utilities/get-meeting-link';
import { makeId } from 'utilities/make-Id';

export const StartMeetingCard = () => {
	const [hasCopied, setHasCopied] = React.useState(false);
	const roomId = makeId(8);
	const meetingLink = getRoomLink(roomId);

	const getMeetingLink = () => {
		navigator.clipboard.writeText(meetingLink);
	};

	const handleCopyMeetingLink = () => {
		getMeetingLink();

		setHasCopied(true);

		const timeout = setTimeout(() => {
			setHasCopied(false);
		}, 3000);

		return () => clearTimeout(timeout);
	};

	const handleJoinMeeting = () => {
		window.location.assign(meetingLink);
	};

	return (
		<DashboardPageCard
			title="Start a meeting"
			description="Instantly create a meeting room with your own personalized link."
		>
			<div className={startMeetingCard.buttons}>
				<Button
					label={hasCopied ? 'Copied!' : 'Copy Meeting Link'}
					variant="grey"
					subVariant="outline"
					iconLeft="Copy"
					onClick={handleCopyMeetingLink}
				/>
				<Button label="Start Meeting" onClick={handleJoinMeeting} />
			</div>
		</DashboardPageCard>
	);
};
