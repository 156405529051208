import { css } from '@emotion/css';
import { theme } from 'cymantic-ui';

export const listView = {
	container: css`
		align-items: flex-start;
		border-radius: ${theme.borderRadius.radiusMD};
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space8};
		justify-content: space-between;
		padding: ${theme.space.space16};
		position: relative;
		width: 100%;

		:hover {
			background-color: ${theme.color.purple50};
		}
	`,
	selectButton: css`
		height: 100%;
		left: 0;
		padding: 0;
		position: absolute;
		top: 0;
		width: 100%;
	`,

	selected: css`
		background-color: ${theme.color.purple50};
		border: 1px solid ${theme.color.purple600};
	`
};
