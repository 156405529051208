import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const container = css`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space16};
	justify-content: center;

	> * {
		max-width: 20rem;
		width: 100%;
	}
`;
