import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { generateRequestSignature } from 'sign';

const host = process.env.REACT_APP_API_HOST;

const baseQuery = fetchBaseQuery({
	baseUrl: `${host}/api/`,
	prepareHeaders: async (headers) => {
		headers.set('Content-Type', 'application/json');

		const signature = await generateRequestSignature();

		headers.set('X-RUTHI-Request-Id', signature.requestId);
		headers.set('X-Ruthi-Request-Token', signature.token);

		const token = Cookies.get('idToken');
		headers.set('X-RUTHI-Authorization', `Bearer ${token}`);

		return headers;
	}
});

/**
 * This is the base api
 * @use TO ADD ENDPOINTS - features/myfeature/feature-queries.ts => apiBase.injectEndpoints
 */
export const ruthiApiBase = createApi({
	reducerPath: 'ruthi-api',
	baseQuery,
	endpoints: () => ({})
});
