import { TranscriptItem, TranscriptPayload } from 'redux/features/meet/meet.definitions';
import { meetApiBase } from 'redux/meet-api-base';

export const meetQueries = meetApiBase.injectEndpoints({
	endpoints: (builder) => ({
		getTemporaryTranscripts: builder.query<TranscriptItem[], string>({
			query: (roomId) => ({
				method: 'GET',
				url: `rooms/${roomId}/history`
			})
		}),
		uploadTranscript: builder.query<void, TranscriptPayload>({
			query: (payload) => ({
				method: 'POST',
				url: 'api/upload',
				body: JSON.stringify(payload, null, 2)
			})
		})
	})
});

export const {
	useGetTemporaryTranscriptsQuery,
	useLazyGetTemporaryTranscriptsQuery,
	useLazyUploadTranscriptQuery,
	useUploadTranscriptQuery
} = meetQueries;
