import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClusterQuery, SearchState } from 'redux/features/search/search.definitions';

const initialState: SearchState = {
	clusterQuery: {
		query: '',
		index: '',
		lens: ''
	},
	activeDatabase: undefined
};

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setActiveDatabase: (state, action: PayloadAction<string>) => {
			const activeDatabase = action.payload;

			const returnState: SearchState = {
				...state,
				activeDatabase
			};

			return returnState;
		},
		setClusterQuery: (state, action: PayloadAction<ClusterQuery>) => {
			const clusterQuery = action.payload;
			const returnState: SearchState = {
				...state,
				clusterQuery
			};
			return returnState;
		}
	}
});

export const { setActiveDatabase, setClusterQuery } = searchSlice.actions;
export const searchReducer = searchSlice.reducer;
