import { dashboardPageCard } from 'components/DashboardPage/DashboardPageCard.styles';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import React from 'react';

type DashboardCardProps = {
	title: string;
	description?: string;
	children: React.ReactNode;
};

export const DashboardPageCard = ({ title, children, description }: DashboardCardProps) => (
	<section className={dashboardPageCard.root}>
		<div className={dashboardPageCard.header}>
			<Text tag="h2" variant="headerH5" color="grey800">
				{title}
			</Text>
		</div>

		<div className={dashboardPageCard.body}>
			{description && (
				<Text variant="bodyXS" color="grey700">
					{description}
				</Text>
			)}
			{children}
		</div>
	</section>
);
