import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, User } from 'redux/features/auth/auth.definitions';

const initialState: AuthState = {
	isSignedIn: false,
	user: {}
};

export const authSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setUserData: (state, action: PayloadAction<User>) => {
			const userData = action.payload;

			const returnState: AuthState = {
				...state,
				user: {
					...state.user,
					...userData
				}
			};

			return returnState;
		},
		setSignedIn: (state, action: PayloadAction<boolean>) => {
			const isSignedIn = action.payload;

			const returnState: AuthState = {
				...state,
				isSignedIn
			};

			return returnState;
		}
	}
});

export const { setUserData, setSignedIn } = authSlice.actions;
export const authReducer = authSlice.reducer;
