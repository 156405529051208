import { pageSection } from 'components/PageSection/PageSection.styles';
import { SkeletonTextLoader } from 'cymantic-ui/dist/atomic-components/skeleton-loader';
import { Text } from 'cymantic-ui/dist/atomic-components/text';

type PageSectionProps = {
	isLoading?: boolean;
	title: string;
	description?: string;
	children: React.ReactNode;
};

export const PageSection = ({ isLoading, title, description, children }: PageSectionProps) => (
	<section className={pageSection.root}>
		{isLoading && (
			<div className={pageSection.header}>
				<SkeletonTextLoader variant="headerH5" width="100%" maxWidth="200px" />
				{description && (
					<SkeletonTextLoader variant="bodyXXS" width="100%" maxWidth="500px" />
				)}
			</div>
		)}
		{!isLoading && (
			<div className={pageSection.header}>
				<Text variant="headerH5" color="grey900">
					{title}
				</Text>
				{description && (
					<Text variant="bodyXXS" color="grey700">
						{description}
					</Text>
				)}
			</div>
		)}
		{children}
	</section>
);
