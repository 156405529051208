import { IconButton, Tooltip, TooltipContent } from 'cymantic-ui/dist/atomic-components';
import { useUrlSearchPage } from 'hooks/use-url-search-page';
import { useAppSelector } from 'redux/hooks';

type ZoomInButtonProps = {
	isDisabled: boolean;
};

export const ZoomInButton = ({ isDisabled }: ZoomInButtonProps) => {
	const cluster = useAppSelector((state) => state.cluster);

	const { urlInput, handleClusterSearch } = useUrlSearchPage();

	const handleZoomIn = () => {
		const obj = {
			query: urlInput.query,
			index: urlInput.index,
			lens: urlInput.lens,
			file: urlInput.file,
			selectedMemberDocs: [...cluster.selectedMemberDocs],
			depth: urlInput.depth + 1
		};

		handleClusterSearch(obj, 'zoomIn');
	};

	return (
		<Tooltip size="sm">
			<IconButton
				icon="ZoomIn"
				onClick={handleZoomIn}
				variant="grey"
				subVariant="outline"
				ariaLabel="Zoom In"
				size="md"
				disabled={isDisabled}
			/>
			<TooltipContent>
				<div style={{ textAlign: 'center' }}>Zoom In on Selected Clusters</div>
			</TooltipContent>
		</Tooltip>
	);
};
