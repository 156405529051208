import {
	CloseButton,
	FlexBox,
	ScreenReaderOnly,
	Select,
	SkeletonTextLoader,
	Text
} from 'cymantic-ui/dist/atomic-components';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { SummaryType } from 'redux/features/summary';
import { useAppSelector } from 'redux/hooks';
import { DocList } from 'routes/SearchPage/doc-panel/doc-list';
import { DocListSkeleton } from 'routes/SearchPage/doc-panel/doc-list-skeleton';
import { docPanel } from 'routes/SearchPage/doc-panel/doc-panel.styles';
import { useDocPanel } from 'routes/SearchPage/doc-panel/doc-panel-provider';
import { DocPanelSettingsModal } from 'routes/SearchPage/doc-panel/doc-panel-settings-modal';

type DocPanelResultsTextProps = {
	isLoading: boolean;
	resultsText: string;
	hasSorting: boolean;
	sortedByText: string;
};

const DocPanelResultsText = ({
	isLoading,
	resultsText,
	hasSorting,
	sortedByText
}: DocPanelResultsTextProps) => {
	return isLoading ? (
		<SkeletonTextLoader width="12rem" variant="bodySM" />
	) : (
		<Text tag="h3" variant="bodySM">
			<span aria-live="polite" role="status">
				{resultsText}
				{hasSorting && <ScreenReaderOnly>{sortedByText}</ScreenReaderOnly>}
			</span>
		</Text>
	);
};
const summaryText: Record<SummaryType, string> = {
	abstractive:
		'Summaries are generated by AI using the content from the original text as a reference and may display inaccurate information. To update how summaries are created see panel settings.',
	extractive:
		'Summaries are constructed from sentences present in original text. To update how summaries are created see panel settings.'
};

const DocPanelSort = () => {
	const { data, onSort } = useDocPanel();

	const sortOptions =
		data.sortOptions?.options.map((option) => ({
			label: option,
			value: option
		})) ?? [];
	return (
		<>
			{/* 
			Accessibility note:
			A description of what will happen on change is provided since we are not using a submit button.
			Relates to WCAG 3.2.2 & 3.3.2 
			Note on technique: https://www.w3.org/TR/2016/NOTE-WCAG20-TECHS-20161007/G13
		*/}
			<ScreenReaderOnly>
				Results list will be sorted when an option is selected.
			</ScreenReaderOnly>
			<form aria-label="Sort results">
				<Select
					hideLabel
					label="Sort by"
					options={sortOptions}
					variant="sm"
					onChange={(e) => onSort(e.target.value)}
				/>
			</form>
		</>
	);
};

const expandedWidth = '30rem';
const duration = 0.4;

type DocPanelProps = {
	isLoading: boolean;
	isExpanded: boolean;
	handleClose: () => void;
};

const DocPanel = ({ isExpanded, isLoading, handleClose }: DocPanelProps) => {
	const { data, isLoadingDocs, summaryType } = useDocPanel();
	const selectedMemberDocs = useAppSelector((state) => state.cluster.selectedMemberDocs);
	const totalDocCount = useAppSelector((state) => state.cluster.totalDocCount);
	const query = useAppSelector((state) => state.search.clusterQuery);
	const isPubMed = query?.index === 'pubmed';
	const docCount = selectedMemberDocs.length > 0 ? selectedMemberDocs.length : totalDocCount;

	const hasSorting = !!data.sortOptions && data.sortOptions.options.length > 1;

	const resultsText = React.useMemo(() => {
		return `Showing ${docCount} ${docCount !== 1 ? 'Results' : 'Result'}`;
	}, [docCount]);

	const sortedByText = React.useMemo(() => {
		return `Sorted by ${data.selectedSortOption}`;
	}, [data.selectedSortOption]);

	return (
		<motion.div
			initial={false}
			animate={
				!isExpanded
					? { width: '0', minWidth: '0' }
					: { width: expandedWidth, minWidth: expandedWidth }
			}
			transition={{ duration }}
			style={{ overflow: 'hidden', height: '100%' }}
		>
			<AnimatePresence>
				{isExpanded && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						style={{ width: expandedWidth, height: '100%' }}
						transition={{ duration }}
					>
						<section className={docPanel.root}>
							<div className={docPanel.header}>
								<div className={docPanel.headerInner}>
									<ScreenReaderOnly>
										<h2>Results Panel</h2>
									</ScreenReaderOnly>
									<div className={docPanel.headerLeft}>
										<DocPanelResultsText
											isLoading={isLoading || isLoadingDocs}
											resultsText={resultsText}
											hasSorting={hasSorting}
											sortedByText={sortedByText}
										/>

										<FlexBox direction="row" gap="space8">
											{isPubMed && (
												/**
												 * TODO: remove hardcoded feature flag (Only pubmed supports extractive and abstractive summaries for now)
												 * This will be fixed when the lenses route is updated to return other index/db configurations (ex: whether extractive and abstractive summary types are supported)
												 */

												<DocPanelSettingsModal />
											)}

											{isLoading || isLoadingDocs
												? null
												: hasSorting && <DocPanelSort />}
										</FlexBox>
									</div>

									<CloseButton
										onClick={handleClose}
										ariaLabel="Close panel"
										size="md"
									/>
								</div>
								{isPubMed && (
									<Text tag="div" variant="bodyTiny" color="grey600">
										{summaryText[summaryType]}
									</Text>
								)}
							</div>
							{isLoading || isLoadingDocs ? <DocListSkeleton /> : <DocList />}
						</section>
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
};

export default DocPanel;
